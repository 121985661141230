import { VStack, Text } from "@chakra-ui/react"
import "./swiper-item.component.css"

interface IProps {
    body: JSX.Element
    header: string
    subheader: string
    color: string
    backgroundColor: string
}
export function SwiperItem({ body, header, subheader, color, backgroundColor }: IProps) {
    return (
        <VStack px={6} justifyContent={"center"} backgroundColor={backgroundColor} className="swiper-item">
            {body}
            <Text textAlign={"center"} mt={4} fontSize={"2xl"} color={color}>{header}</Text>
            <Text textAlign={"center"} mb={6} fontSize={"md"} color={color}>{subheader}</Text>
        </VStack>
    )
}