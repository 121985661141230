import { Card, CardBody, Stack, Heading, Image, Text, HStack, Spacer, CloseButton, VStack, Button } from "@chakra-ui/react";
import { GOnetMunicipalite } from "portail-api-models";
import { useCallback } from "react";
import { environment } from "../../../config/environment";

interface IProps { municipality: GOnetMunicipalite, subtext?: string, onClick: () => void, onDelete?: () => void }
export function MunicipalityCard({ municipality, subtext, onClick, onDelete }: IProps) {

    const getLogoUrl1 = useCallback((m: GOnetMunicipalite) => {
        const flatAlias = m.nom.replaceAll("-", "").replaceAll("'", "")
        return environment.LOGO_URL_1.PREFIX + flatAlias + environment.LOGO_URL_1.SUFFIX
    }, [])

    const getLogoUrl2 = useCallback((m: GOnetMunicipalite) => {
        return environment.LOGO_URL_2.PREFIX + m.code + environment.LOGO_URL_2.SUFFIX
    }, [])

    return (
        <Card flexShrink={0} minW={60} shadow={"lg"}>
            <CardBody p={3}>
                <HStack alignItems={"start"}>
                    <Button onClick={onClick} justifyContent={"start"} variant={"ghost"} height={"auto"} p={1} flexGrow={1}>
                        <VStack alignItems={"start"}>
                            <Image
                                maxHeight={"10"}
                                src={getLogoUrl1(municipality)}
                                fallbackSrc={getLogoUrl2(municipality)}
                                alt='Logo'
                                borderRadius='lg'
                            />
                            <Stack mt='2' spacing='0' alignItems={"start"}>
                                <Heading size='md'>{municipality.nom}</Heading>
                                <Text>
                                    {municipality.code}
                                </Text>
                                {subtext && <Text>{subtext}</Text>}
                            </Stack>
                        </VStack>
                    </Button>

                    {onDelete &&
                        <>
                            <Spacer />
                            <CloseButton onClick={onDelete} />
                        </>}
                </HStack>
            </CardBody>
        </Card>
    )
}