import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export function useRoutingDisclosureForTabs(animationDuration: number) {

    const [tabIndex, setTabIndex] = useState(0)
    const [inTransition, setInTransition] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const setFutureTabIndex = useCallback((index: number) => {
        if (index === 0) navigate("/dashboard")
        else {
            navigate("tabs/" + index, { state: { backgroundLocation: true } })
        }
    }, [navigate])

    const futureTabIndex = useMemo(() => {
        if (!location.pathname.includes("tabs")) return 0
        const nestedPaths = location.pathname.split("/")
        const _tabIndex = Number(nestedPaths[nestedPaths.length - 1])
        return _tabIndex
    }, [location])

    useEffect(() => {
        if (futureTabIndex === tabIndex) return
        setTimeout(() => {
            setTabIndex(futureTabIndex)
            setInTransition(false)
        }, animationDuration)
    }, [futureTabIndex, tabIndex, setTabIndex, setInTransition, animationDuration])

    const onTabChange = useCallback((i: number) => {
        setFutureTabIndex(i)
        setInTransition(true)
    }, [setInTransition, setFutureTabIndex])

    return { tabIndex, futureTabIndex, inTransition, onTabChange }
}