import { IsOptional, IsString, ValidateNested } from "class-validator"
import { Geoprojet } from "./geoprojet.model"

export class SharedGeoprojet {
    @IsString()
    id: string
    @IsOptional()
    @IsString()
    expiration?: string
    @ValidateNested()
    geoprojet: Geoprojet

    constructor(id: string, expiration: string, geoprojet: Geoprojet) {
        this.id = id
        this.expiration = expiration
        this.geoprojet = geoprojet
    }
}

export interface ISharedGeoprojet extends SharedGeoprojet { }