import { IconButton, Icon, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, VStack, PopoverHeader } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AuthService, useService } from "portail-api-client";
import { GOnetMunicipalite } from "portail-api-models"
import GOnet from "./session/gonet.component";
import { Session } from "../../../types/session.type";
import GOrequete from "./session/gorequete.component";
import GOgfs from "./session/gogfs.component";

interface IProps { municipality: GOnetMunicipalite, onOpenWith: (session: Session) => void }
export function OpenWith({ municipality, onOpenWith }: IProps) {

    const { t } = useTranslation();
    const api = useService(AuthService)

    return (
        <>
            <Popover>
                <PopoverTrigger>
                    <IconButton variant={"ghost"} icon={< Icon as={BsThreeDotsVertical} />} aria-label='more'></IconButton >
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>{t("dashboard.openWith")}</PopoverHeader>
                    <PopoverBody p={4}>
                        <VStack alignItems={"stretch"}>
                            {municipality.services.gonetprive && <Button justifyContent={"start"} onClick={() => onOpenWith({ component: GOnet, props: { municipality, gde: api.getGDE() } })}>{t("dashboard.gonet")} {t("dashboard.gonetdefault")}</Button>}
                            {municipality.services.gonetpublic && <Button justifyContent={"start"} onClick={() => onOpenWith({ component: GOnet, props: { municipality } })}> {t("dashboard.gonetpublic")}</Button>}
                            {municipality.services.gorequete && <Button justifyContent={"start"} onClick={() => onOpenWith({ component: GOrequete, props: { municipality } })}> {t("dashboard.gorequete")}</Button>}
                            {municipality.services.gogfs && <Button justifyContent={"start"} onClick={() => onOpenWith({ component: GOgfs, props: { municipality } })}> {t("dashboard.gogfs")}</Button>}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}