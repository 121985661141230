import './app.css';
import DashboardPage from './pages/dashboard/dashboard.page';
import { ChakraProvider } from '@chakra-ui/react'
import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import { LoginPage } from './pages/login/login.page';
import { extendTheme } from "@chakra-ui/react"
import { AuthService, useService } from "portail-api-client";
import { useCallback, useEffect, useState } from 'react';
import { TokenRepository } from './repositories/token.repository';
import { useInit, useInitSync } from './hooks/init.hook';
import { AppContext } from './app.context';
import { INSTALL } from './pwa-install';
import { LanguageRepository } from './repositories/language.repository';
import i18next from 'i18next';
import { IOptions } from './interfaces/options.interface';
import { OptionsRepository } from './repositories/options.repository';
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { IFeatureSupport } from './interfaces/feature-support.interface';
import { detect } from "detect-browser"
import { analytics } from './google-analytics';
const browser = detect()

function App() {

  const api = useService(AuthService)
  const tokenRepository = useService(TokenRepository)
  const languageRepository = useService(LanguageRepository)
  const optionsRepository = useService(OptionsRepository)
  const [authentified, setAuthentified] = useState(false)
  const [ready, setReady] = useState(false)
  const [installed, setInstalled] = useState(false)
  const [options, setOptions] = useState<IOptions>({ clipboard: false, clipboardAutomatic: false, duplicateTabs: false, restoreSession: true })
  const [featureSupport] = useState<IFeatureSupport>({ clipboard: browser?.name === "chrome" })
  const install = useCallback(async () => {
    if (!INSTALL) {
      console.error("install event not available")
      return
    }
    analytics.track("pwaInstallPrompted")
    INSTALL.prompt()
    const { outcome } = await INSTALL.userChoice;
    if (outcome === "accepted") {
      analytics.track("userInstalledPWA")
      setInstalled(true)
    }
    else {
      analytics.track("userRefusedPWAInstallation")
    }
  }, [setInstalled])

  useInit(async () => {
    const installed = window.matchMedia('(display-mode: standalone)').matches
    setInstalled(installed)
    if (installed)
      analytics.track("pwaIsAlreadyInstalled")
    console.log('PWA installed:', installed)

    const language = await languageRepository.get();
    if (language)
      i18next.changeLanguage(language)

    const _options = await optionsRepository.get();
    if (_options)
      setOptions(_options)

    const token = await tokenRepository.get()
    if (token) {
      try {
        api.setToken(token)
        const updatedtoken = await api.authentificateWithRefreshToken()
        await tokenRepository.set(updatedtoken)
        api.setToken(updatedtoken)
        setAuthentified(true)
      }
      catch (e) { console.error(e) }
    }
  }, () => setReady(true))

  useInitSync(() => {

    function onMessage(ev: MessageEvent<any>) {
      //console.log(ev)
      //window.open(ev.data, "_blank")
    }
    window.addEventListener("message", onMessage)

    return () => {
      window.removeEventListener("message", onMessage)
    }
  })

  useEffect(() => {
    if (!ready) return;
    optionsRepository.set(options)
  }, [ready, options, setOptions, optionsRepository])


  const persistLanguage = useCallback(async (_language: string) => {
    languageRepository.set(_language)
    i18next.changeLanguage(_language)
  }, [languageRepository])

  const theme = extendTheme({
    components: {
      Button: {
        // 1. We can update the base styles
        baseStyle: {
          color: 'bold', // Normally, it is "semibold"
        },
      }
    }
  })

  const location = useLocation();

  const backgroundLocation = Boolean(location.state?.backgroundLocation)

  return (
    <AppContext.Provider value={{ featureSupport, setLanguage: persistLanguage, options, setOptions, installed, setInstalled, authentified, setAuthentified, install }}>
      <ChakraProvider theme={theme}>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname.split('/')[1]}
            classNames="fade"
            timeout={300}
          >
            <Routes location={location}>
              {/* Si tu ajoutes une route, n'oubli pas d'ajouter celle-ci dans service-worker.ts et serviceWorkerRegistration.ts pour que le cache du PWA fonctionne correctement */}
              <Route path="/login/*" element={<LoginPage ready={ready} />} />
              {authentified && <Route path="/dashboard/*" element={<DashboardPage />} />}
              {!backgroundLocation && <Route path="*" element={<Navigate to="/login" replace />} />}
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </ChakraProvider>
    </AppContext.Provider>
  );
}

export default App;
