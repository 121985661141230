import { IsString, ValidateNested } from "class-validator";
import { DesignationMunicipale } from "./designation-municipale.model";
import { MRC } from "./mrc.model";
import { Region } from "./region.model";

export class Municipalite {
	@IsString()
	code: string;
	@IsString()
	nom: string;
	@ValidateNested()
	designation: DesignationMunicipale;
	@ValidateNested()
	mrc: MRC
	@ValidateNested()
	region: Region

	constructor(
		code: string,
		nom: string,
		designation: DesignationMunicipale,
		mrc: MRC,
		region: Region
	) {
		this.code = code
		this.nom = nom
		this.designation = designation
		this.mrc = mrc
		this.region = region
	}
}

export interface IMunicipalite extends Municipalite { }