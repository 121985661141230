import "./dashboard.page.css"
import Favorites from "./components/favorites.component";
import Municipalities from "./components/municipalities.component";
import { Box, Container, Text, HStack, Spacer, Button, Icon, AlertIcon, Alert, IconButton, Fade, Collapse, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MunicipaliteService, useService } from "portail-api-client";
import { GOnetMunicipalite, SharedGeoprojet } from "portail-api-models"
import { useNavigate } from "react-router-dom";
import { useInit, useInitSync } from "../../hooks/init.hook";
import { AppContext } from "../../app.context";
import { Options } from "./components/options.component";
import { MdInstallDesktop } from "react-icons/md"
import { INSTALL } from "../../pwa-install";
import { FavoriteRepository } from "../../repositories/favorite.repository";
import { environment } from "../../config/environment";
import { Session } from "../../types/session.type";
import { SessionSwitcher } from "./components/session-switcher.component";
import { GetSessionTypeName } from "../../utils/tostring";
import { UpdateApp } from "./components/update-app.component";
import { GeoprojectRepository } from "../../repositories/geoproject.repository";
import { Geoprojects } from "./components/geoprojects.component";
import { DashboardContext } from "./contexts/dashboard.context";
import { Favorite } from "../../repositories/models/favorite.model";
import { Tabs, TabPanel, TabList, Tab } from "react-tabs"
import 'react-tabs/style/react-tabs.css';
import { SessionActions } from "./components/session-actions.component";
import { SessionActionContext } from "./contexts/session-actions.context";
import { WithMunicipality } from "../../interfaces/with-municipality.interface";
import { HiMenu } from "react-icons/hi";
import { useRoutingDisclosureForTabs } from "./hooks/routing-disclosure-for-tabs.hook";
import { DashboardJoyride } from "./components/dashboard-joyride.component";
import { GOnetConnector } from "../../services/gonet-connector.service";

const animationDuration = 250;
export default function DashboardPage() {

    const navigate = useNavigate();

    const [ready, setReady] = useState(false)

    const [virtuosoScrollParent, setVirtuosoScrollParent] = useState<HTMLElement>()

    const [sessions, setSessions] = useState(new Array<Session>())
    const [favorites, setFavorites] = useState(new Array<WithMunicipality<Favorite>>())
    const [geoprojects, setGeoprojects] = useState(new Array<WithMunicipality<SharedGeoprojet>>())
    const [manualClipboardRequest, setManualClipboardRequest] = useState(false)
    const [restartRequest, setRestartRequest] = useState(false)
    const { t } = useTranslation();
    const { tabIndex, futureTabIndex, inTransition, onTabChange } = useRoutingDisclosureForTabs(animationDuration)
    const [municipalities, setMunicipalities] = useState(new Array<GOnetMunicipalite>())

    const services = useService(MunicipaliteService)
    const favoriteRepository = useService(FavoriteRepository)
    const geoprojectRepository = useService(GeoprojectRepository)
    const gonetConnector = useService(GOnetConnector)
    const { authentified, install, installed } = useContext(AppContext)
    const { duplicateTabs } = useContext(AppContext).options
    const toast = useToast()

    const addSession = useCallback(async (session: Session) => {

        if (!duplicateTabs) {
            const existingIndex = sessions.findIndex(s => s.props.municipality.code === session.props.municipality.code)
            if (existingIndex >= 0) {
                setSessions(prev => prev.map((p, i) => existingIndex !== i ? p : session))
                onTabChange(existingIndex + 1)
                return;
            }
        }
        const currentSessionLength = sessions.length
        setSessions(prev => [...prev, session])
        onTabChange(currentSessionLength + 1)

        setFavorites(prev => {
            const _filtered = prev.filter(f => f.model.codeMuni !== session.props.municipality.code)
            _filtered.unshift(new WithMunicipality(new Favorite(session.props.municipality.code, session.props.municipality.designation.nom), session.props.municipality))
            const _favorites = _filtered.slice(0, environment.MAX_FAVORITES)
            return _favorites
        })

    }, [sessions, setSessions, onTabChange, setFavorites, duplicateTabs])

    const removeSession = useCallback((index: number) => {
        if (tabIndex === index + 1) {
            onTabChange(tabIndex - 1)
        }

        setTimeout(() => setSessions(prev => prev.filter((p, i) => index !== i)), animationDuration)
    }, [tabIndex, setSessions, onTabChange])

    const startPos = useMemo(() => sessions.length === 0 ? " no-start-offset" : "", [sessions])

    useEffect(() => {
        if (!authentified)
            navigate("/login")
    }, [authentified, navigate])

    useEffect(() => {
        if (tabIndex > sessions.length)
            navigate("/dashboard")
    }, [navigate, tabIndex, sessions])

    useEffect(() => {
        if (!ready) return
        favoriteRepository.set(favorites.map(f => f.model))
    }, [ready, favoriteRepository, favorites])

    useEffect(() => {
        if (!ready) return
        geoprojectRepository.set(geoprojects.map(g => g.model))
    }, [ready, geoprojectRepository, geoprojects])

    useEffect(() => {

    }, [])

    useInit(async () => {
        const _muniticpalities = await services.get()
        setMunicipalities(_muniticpalities)
        const _favorites = (await favoriteRepository.get()) || []
        const favorites = _favorites.map(f => new WithMunicipality(f, _muniticpalities.find(m => m.code === f.codeMuni && m.designation.nom === f.designation) as GOnetMunicipalite)).filter(m => m !== undefined)
        setFavorites(favorites)
        const _geoprojects = (await geoprojectRepository.get()) || []
        const geoprojects = _geoprojects.map(g => new WithMunicipality(g, _muniticpalities.find(m => m.code === g.geoprojet.profilUtilisateur.codemuni) as GOnetMunicipalite)).filter(m => m !== undefined)
        setGeoprojects(geoprojects.filter(g => g.municipality != null))
        setReady(true)
    })

    const tabAnimation = useCallback((i: number) => {
        if (futureTabIndex === i) return ""
        const hideTab = (tabIndex !== i) ? " hide-tab" : ""
        const animate = (i < futureTabIndex) ? " animate-out-left" : " animate-out-right"
        return animate + hideTab
    }, [futureTabIndex, tabIndex])


    useInitSync(() => {
        gonetConnector.listen(() => {
            toast.closeAll()
            return toast({
                title: t("iframeMessaging.noResult.title"),
                description: t("iframeMessaging.noResult.subtitle"),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        })
        return () => {
            gonetConnector.stopListening()
        }
    })


    return (
        <DashboardContext.Provider value={{ ready, setReady, favorites: favorites, setFavorites, geoprojects, setGeoprojects, municipalities, setMunicipalities, sessions, setSessions }} >
            <SessionActionContext.Provider value={{ manualClipboardRequest, setManualClipboardRequest, restartRequest, setRestartRequest }}>
                {ready && <DashboardJoyride />}
                <Container maxW='full' height={"full"} p="0">
                    <Tabs forceRenderTabPanel={true} className="full-flex" selectedIndex={tabIndex} onSelect={(i) => { onTabChange(i) }}>
                        <TabList style={{ display: "none" }}>
                            <Tab>menu</Tab>
                            {sessions.map((s, i) => (<Tab key={s.props.municipality.code}>{s.props.municipality.code}</Tab>))}
                        </TabList>
                        <Collapse in={sessions.length > 0} animateOpacity>
                            <HStack className={"topbar " + (futureTabIndex > 0 ? "topbar-shadow" : "")} justifyContent={"end"}>
                                {tabIndex > 0 && futureTabIndex > 0 && tabIndex <= sessions.length &&
                                    <Fade in={!inTransition} className="flex">
                                        <HStack className="flex">
                                            <IconButton onClick={() => onTabChange(0)} variant={"ghost"} icon={<Icon boxSize={"5"} as={HiMenu} />} aria-label="home"></IconButton>
                                            <Spacer />
                                            <Text fontSize={["xs", "sm", "md"]} className="ellipsis">{sessions[tabIndex - 1].props.municipality.nom} ({t(`dashboard.${GetSessionTypeName(sessions[tabIndex - 1])}`)})</Text>
                                            <SessionActions />
                                        </HStack>
                                    </Fade>
                                }

                                {sessions.length > 0 &&
                                    <SessionSwitcher tabIndex={tabIndex} sessions={sessions} onRemoveSession={removeSession} onSessionSwitch={onTabChange} />
                                }
                            </HStack>
                        </Collapse>
                        <TabPanel className={"animated-tab" + tabAnimation(0) + startPos}>
                            <Box className="animated-tab-scrollbox" ref={r => { r && setVirtuosoScrollParent(r) }}>
                                {!installed && INSTALL && (
                                    <Box p={4} pb={0}>
                                        <Alert status='info'>
                                            <AlertIcon display={["none", "inherit"]} />
                                            <Text fontSize='lg'>{t("dashboard.installHeader")}</Text>
                                            <Spacer minWidth={4}></Spacer>
                                            <Box alignSelf={"end"}>
                                                <Button id="dashboard-install-button" isDisabled={installed} onClick={install} rightIcon={<Icon as={MdInstallDesktop} />} colorScheme='teal'>
                                                    {t("common.install")}
                                                </Button>
                                            </Box>
                                        </Alert>
                                    </Box>
                                )}
                                <UpdateApp />
                                {(favorites.length > 0 || !ready) &&
                                    <div id="favorites">
                                        <HStack>
                                            <Text fontSize='xl' fontWeight={"bold"} p={4} pb={0}>{t("dashboard.favorites")}</Text>
                                            <Spacer />
                                            <Box p={4} pb={0}>
                                                <Options />
                                            </Box>
                                        </HStack>
                                        <Favorites onClick={addSession}></Favorites>
                                    </div>
                                }
                                {(geoprojects.length > 0 || !ready) &&
                                    <>

                                        <HStack>
                                            <Text fontSize='xl' fontWeight={"bold"} p={4} pb={0}>{t("dashboard.geoprojects")}</Text>
                                            {favorites.length === 0 && ready &&
                                                <>
                                                    <Spacer />
                                                    <Box p={4} pb={0}>
                                                        <Options />
                                                    </Box>
                                                </>
                                            }
                                        </HStack>

                                        <Geoprojects onClick={addSession} />
                                    </>
                                }
                                <Box px="4">
                                    <HStack>
                                        <Text fontSize='xl' py={4} fontWeight={"bold"} >{t("dashboard.municipalities")}</Text>
                                        <Spacer />
                                        {favorites.length === 0 && geoprojects.length === 0 && ready && <Options />}
                                    </HStack>
                                    {virtuosoScrollParent && <Municipalities customScrollParent={virtuosoScrollParent} onClick={addSession}></Municipalities>}
                                </Box>
                            </Box>
                        </TabPanel>
                        {sessions.map((s, i) => (
                            <TabPanel className={"animated-tab" + tabAnimation(i + 1)} key={s.props.municipality.code}>
                                <s.component {...s.props} onClose={() => removeSession(i)} focused={tabIndex === i + 1} />
                            </TabPanel>
                        ))}
                    </Tabs>
                </Container >
            </SessionActionContext.Provider>
        </DashboardContext.Provider>
    )
}