import { useService } from "portail-api-client"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useInit } from "../../../../../hooks/init.hook"
import { OnbaordingRepository } from "../../../../../repositories/onboarding.repository"
import { Step } from "react-joyride"
import Joyride from "react-joyride"

export function GOnetJoyride() {



    const { t } = useTranslation()
    const onboardingRepository = useService(OnbaordingRepository)
    const [showJoyride, setShowJoyride] = useState(false)

    const joyrideSteps: Array<Step> = [
        {
            disableBeacon: true,
            target: ".session-switcher-button",
            floaterProps: { placement: "bottom" },
            content: t("onboarding.joyride.sessionSwitcher")
        },
        {
            disableBeacon: true,
            target: ".session-actions-button",
            floaterProps: { placement: "bottom" },
            content: t("onboarding.joyride.sessionActions")
        },
        {
            disableBeacon: true,
            target: ".gonet-iframe",
            floaterProps: { placement: "center" },
            content: t("onboarding.joyride.gonetIframe")
        }
    ]

    useInit(async () => {
        const progress = await onboardingRepository.get()
        if (!progress?.gonet) {
            setShowJoyride(true)
            onboardingRepository.set({ slider: !!progress?.slider, dashboard: !!progress?.dashboard, gonet: true })
        }
    })

    return (
        <>
            {showJoyride && <Joyride
                continuous={true}
                steps={joyrideSteps}
                disableScrolling
            />
            }
        </>
    )

}