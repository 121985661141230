

export const environment = {
    GONET: {
        BASE: "/GOnet6/",
        PARAMETERS: {
            CODE_MUNI: "m",
            PUBLIC: "pl",
            GDE: "gde",
            LANG: "lang",
            IFRAMEMODE: "gdm",
            GEOPROJECT: "geoprojet",
            RESTORE_SESSION: "reload"
        },
        IFRAME_MESSAGING: {
            COMMANDS: {
                OPEN_MATRICULES: "vplusOnUESelection",
                OPEN_MATRICULES_RESPONSE: "vplusOnUESelectionResponse"
            }
        }
    },
    GOREQUETE: {
        BASE: ""
    },
    GOGFS: {
        BASE: ""
    },
    LOGO_URL_1: {
        PREFIX: "https://www.goazimut.com/GOnet6/images/Print/",
        SUFFIX: "_print.png",
    },
    LOGO_URL_2: {
        PREFIX: "https://www.goazimut.com/GOnet6/images/logos/",
        SUFFIX: ".png",
    },
    MAX_FAVORITES: 5,
    VERSION: process.env.PACKAGE_VERSION,
    USER_REPORT_SENT_DELAY: 7000,
    USER_REPORT_APPNAME: "gonet-app",
    GOOGLE_ANALYTICS: {
        GOOGLE_TAG_MANAGER_ID: "GTM-KPHPG364",
        GOOGLE_ANALYTICS_ID: "G-VDLTZY6JLB"
    }
}