import { IsNumber, IsString, ValidateNested } from "class-validator";
import { ProfilUtilisateur } from "./profil-utilisateur.model";
import { Service } from "./service.model";

export class Geoprojet {
    @IsString()
    id: string
    @ValidateNested()
    profilUtilisateur: ProfilUtilisateur
    @ValidateNested()
    service: Service
    @IsString()
    nom: string
    @IsString()
    description: string
    @IsNumber()
    dateCreation: number		// Unix timestamp
    @IsNumber()
    accessibilite: number

    constructor(
        id: string,
        profilUtilisateur: ProfilUtilisateur,
        service: Service,
        nom: string,
        description: string,
        dateCreation: number,
        accessibilite: number
    ) {
        this.id = id
        this.profilUtilisateur = profilUtilisateur
        this.service = service
        this.nom = nom
        this.description = description
        this.dateCreation = dateCreation
        this.accessibilite = accessibilite
    }
}

export interface IGeoprojet extends Geoprojet { }