import { IsString } from "class-validator";

export class Region {
    @IsString()
    code: string
    @IsString()
    nom: string

    constructor(code: string, nom: string) {
        this.code = code
        this.nom = nom
    }
}

export interface IRegion extends Region { }