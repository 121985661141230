import { IsString, ValidateNested } from "class-validator"
import { Profil } from "./profil.model"
import { Utilisateur } from "./utilisateur.model"

export class ProfilUtilisateur {
    @IsString()
    groupe: string
    @IsString()
    codemuni: string
    @ValidateNested()
    utilisateur: Utilisateur
    @ValidateNested()
    profil: Profil

    constructor(
        groupe: string,
        codemuni: string,
        utilisateur: Utilisateur,
        profil: Profil
    ) {
        this.groupe = groupe
        this.codemuni = codemuni
        this.utilisateur = utilisateur
        this.profil = profil
    }
}

export interface IProfilUtilisateur extends ProfilUtilisateur { }