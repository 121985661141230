import { useEffect } from "react";

var browserPrefixes = ['moz', 'ms', 'o', 'webkit'],
    isVisible = true; // internal flag, defaults to true
// get the correct attribute name
function getHiddenPropertyName(prefix: string) {
    return (prefix ? prefix + 'Hidden' : 'hidden');
}

// get the correct event name
function getVisibilityEvent(prefix: string) {
    return (prefix ? prefix : '') + 'visibilitychange';
}

// get current browser vendor prefix
function getBrowserPrefix() {
    for (var i = 0; i < browserPrefixes.length; i++) {
        if (getHiddenPropertyName(browserPrefixes[i]) in document) {
            // return vendor prefix
            return browserPrefixes[i];
        }
    }

    // no vendor prefix needed
    return "";
}


export function useFocus(iFrame: HTMLIFrameElement | null, onFocus: () => void, activate: boolean) {
    useEffect(() => {
        if ((!iFrame?.contentWindow && !iFrame?.contentDocument) || !activate) return;
        // bind and handle events
        var browserPrefix = getBrowserPrefix(),
            hiddenPropertyName = getHiddenPropertyName(browserPrefix),
            visibilityEventName = getVisibilityEvent(browserPrefix);

        function onVisible() {
            if (isVisible) return;
            if (!document.hasFocus() && !iFrame?.contentDocument?.hasFocus()) return;
            isVisible = true;
            onFocus()
        }

        function onHidden() {
            if (!isVisible) return;
            if (document.hasFocus() || iFrame?.contentDocument?.hasFocus()) return;
            isVisible = false;
        }

        function handleOnFocus() {
            handleVisibilityChange(true);
        }

        function handleOnBlur() {
            handleVisibilityChange(false);
        }

        function handleVisibilityChange(forcedFlag: boolean | Event) {
            // forcedFlag is a boolean when this event handler is triggered by a
            // focus or blur eventotherwise it's an Event object
            if (typeof forcedFlag === "boolean") {
                if (forcedFlag) {
                    return onVisible();
                }

                return onHidden();
            }

            if ((document as any)[hiddenPropertyName]) {
                return onHidden();
            }

            return onVisible();
        }

        document.addEventListener(visibilityEventName, handleVisibilityChange, false);
        iFrame.contentDocument?.addEventListener(visibilityEventName, handleVisibilityChange, false);
        iFrame.contentWindow?.addEventListener(visibilityEventName, handleVisibilityChange, false);

        iFrame.contentDocument?.addEventListener('focus', handleOnFocus, false);
        iFrame.contentDocument?.addEventListener('blur', handleOnBlur, false);
        iFrame.contentWindow?.addEventListener('focus', handleOnFocus, false);
        iFrame.contentWindow?.addEventListener('blur', handleOnBlur, false);

        document.addEventListener('focus', handleOnFocus, false);
        document.addEventListener('blur', handleOnBlur, false);
        window.addEventListener('focus', handleOnFocus, false);
        window.addEventListener('blur', handleOnBlur, false);

        return () => {
            document.removeEventListener(visibilityEventName, handleVisibilityChange, false);
            iFrame.contentDocument?.removeEventListener(visibilityEventName, handleVisibilityChange, false);
            iFrame.contentWindow?.removeEventListener(visibilityEventName, handleVisibilityChange, false);

            iFrame.contentDocument?.removeEventListener('focus', handleOnFocus, false);
            iFrame.contentDocument?.removeEventListener('blur', handleOnBlur, false);
            iFrame.contentWindow?.removeEventListener('focus', handleOnFocus, false);
            iFrame.contentWindow?.removeEventListener('blur', handleOnBlur, false);

            document.removeEventListener('focus', handleOnFocus, false);
            document.removeEventListener('blur', handleOnBlur, false);
            window.removeEventListener('focus', handleOnFocus, false);
            window.removeEventListener('blur', handleOnBlur, false);
        }
    }, [iFrame, onFocus, activate])
}