import { Icon, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, FormControl, FormLabel, Input, FormErrorMessage, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { ChangeEvent, FormEvent, useCallback, useContext, useMemo, useState } from "react";
import { CiImport } from "react-icons/ci";
import { DashboardContext } from "../contexts/dashboard.context";
import { environment } from "../../../config/environment";
import { GeoprojetPartageService, useService } from "portail-api-client";
import { WithMunicipality } from "../../../interfaces/with-municipality.interface";

export interface IProps {
    isOpen: boolean;
    onClose: () => void;
}
export function ImportGeoprojet({ isOpen, onClose }: IProps) {

    const [url, setUrl] = useState("")
    const [dirty, setDirty] = useState(false)
    const { municipalities, setGeoprojects } = useContext(DashboardContext)
    const geoprojetPartage = useService(GeoprojetPartageService)

    const toast = useToast()
    const invalidUrl = useMemo<boolean>(() => {
        const isUrl = url.includes("https://") && url.toLowerCase().includes("gonet")
        if (!isUrl) return true
        const _url = new URL(url)
        const codemuni = _url.searchParams.get(environment.GONET.PARAMETERS.CODE_MUNI)
        if (!codemuni) return true
        const geoprojectId = _url.searchParams.get(environment.GONET.PARAMETERS.GEOPROJECT)
        if (!geoprojectId) return true
        return false
    }, [url])

    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
        setDirty(true)
    }, [setUrl, setDirty])

    const importGeoproject = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const _url = new URL(url)
        const codemuni = _url.searchParams.get(environment.GONET.PARAMETERS.CODE_MUNI)
        const shareId = _url.searchParams.get(environment.GONET.PARAMETERS.GEOPROJECT)
        const municipality = municipalities.find(m => m.code === codemuni)

        if (!shareId) return
        if (!municipality) {
            toast.closeAll()
            return toast({
                title: t("dashboard.importgeoproject.errors.403.title"),
                description: t("dashboard.importgeoproject.errors.403.subtitle") + codemuni,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        }

        try {
            const sharedGeoprojet = await geoprojetPartage.get(shareId)
            if (!sharedGeoprojet?.id) {
                toast.closeAll()
                return toast({
                    title: t("dashboard.importgeoproject.errors.204.title"),
                    description: t("dashboard.importgeoproject.errors.204.subtitle"),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: "top"
                })
            }
            setGeoprojects(prev => prev.find(p => p.model.id === sharedGeoprojet.id) ? prev : [...prev, new WithMunicipality(sharedGeoprojet, municipality)])
            onClose()
        }
        catch (e) {
            console.error(e)
            toast.closeAll()
            return toast({
                title: t("dashboard.importgeoproject.errors.500.title"),
                description: t("dashboard.importgeoproject.errors.500.subtitle"),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        }


    }, [url, municipalities, setGeoprojects, onClose, toast, geoprojetPartage])

    return (
        <Modal preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("dashboard.importgeoproject.header")}</ModalHeader>
                <ModalCloseButton />
                <form id="import-geoprojet-form" onSubmit={importGeoproject} style={{ width: "100%" }}>
                    <ModalBody>

                        <FormControl isInvalid={invalidUrl}>
                            <FormLabel>{t("dashboard.importgeoproject.input")}</FormLabel>
                            <Input type="url" value={url} onChange={onInputChange} />
                            {dirty && <FormErrorMessage>{t("dashboard.importgeoproject.errors.invalidUrl")}</FormErrorMessage>}
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button id="cancel-import-geoprojet" onClick={onClose} colorScheme='teal' variant='outline'>
                            {t("common.cancel")}
                        </Button>
                        <Spacer />
                        <Button isDisabled={invalidUrl} type="submit" leftIcon={<Icon as={CiImport} />} colorScheme='teal' variant='solid'>
                            {t("common.import")}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal >
    )
}