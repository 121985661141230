import { Service } from "typedi";
import { AuthService } from "./auth.service";
import axios from "axios";
import { GeolocationResult, IGeolocationResult } from "portail-api-models";
import { environment } from "../config/environment";
import { APIUrl } from "../utils/api-url";
import { plainToClass } from "class-transformer";

@Service()
export class ServicesService {

    constructor(@Service() private auth: AuthService) { }

    async geolocate(x: number, y: number): Promise<GeolocationResult> {
        await this.auth.beforeRequest()
        const res = await axios.get<IGeolocationResult>(APIUrl(environment.API.ENDPOINTS.geolocate), { params: { x, y } })
        const clazzed = plainToClass(GeolocationResult, res.data)
        return clazzed
    }

}