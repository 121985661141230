import { Popover, PopoverTrigger, IconButton, Icon, PopoverContent, PopoverArrow, PopoverBody, VStack, Button } from "@chakra-ui/react";
import { t } from "i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useCallback, useContext } from "react";
import { CiImport } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { BiBug } from "react-icons/bi"
import { BsLightbulb } from "react-icons/bs"
import { AuthService, useService } from "portail-api-client";
import { AppContext } from "../../../app.context";
import { TokenRepository } from "../../../repositories/token.repository";
import { ImportGeoprojet } from "./import-geoprojet.component.";
import { useRoutingDisclosure } from "../../../hooks/routing-disclosure.hook";
import { Contact } from "./contact.component";

export function DashboardActions() {

    const { isOpen: geoprojetIsOpen, onOpen: geoprojetOnOpen, onClose: geoprojetOnClose } = useRoutingDisclosure("import-geoprojet")
    const { isOpen: requestFeatureIsOpen, onOpen: requestFeatureOnOpen, onClose: requestFeatureOnClose } = useRoutingDisclosure("request-feature")
    const { isOpen: reportBugIsOpen, onOpen: reportBugOnOpen, onClose: reportBugOnClose } = useRoutingDisclosure("report-bug")
    const auth = useService(AuthService)
    const userRepository = useService(TokenRepository)
    const { setAuthentified } = useContext(AppContext)

    const logout = useCallback(async () => {
        auth.logout();
        await userRepository.delete()
        setAuthentified(false)
    }, [auth, setAuthentified, userRepository])

    return (
        <>
            <Popover>
                <PopoverTrigger>
                    <IconButton variant={"ghost"} icon={< Icon as={BsThreeDotsVertical} />} aria-label='more'></IconButton >
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody p={4}>
                        <VStack alignItems={"stretch"} spacing={"4"}>
                            <Button id="open-import-geoprojet" leftIcon={<Icon as={CiImport} />} onClick={geoprojetOnOpen} justifyContent={"start"} >{t("dashboard.importGeoprojectButton")}</Button>
                            <Button id="logout-button" onClick={logout} leftIcon={<Icon as={CiLogout} />} colorScheme='teal' variant='solid' justifyContent={"start"}>
                                {t("dashboard.profile.logout")}
                            </Button>
                            <Button marginTop={"6"} colorScheme="yellow" leftIcon={<Icon as={BsLightbulb} />} onClick={requestFeatureOnOpen} justifyContent={"start"} >{t("contact.feature.title")}</Button>
                            <Button colorScheme="yellow" leftIcon={<Icon as={BiBug} />} onClick={reportBugOnOpen} justifyContent={"start"} >{t("contact.bug.title")}</Button>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover >
            <ImportGeoprojet isOpen={geoprojetIsOpen} onClose={geoprojetOnClose} />
            <Contact isOpen={requestFeatureIsOpen} onClose={requestFeatureOnClose} requestType="feature" />
            <Contact isOpen={reportBugIsOpen} onClose={reportBugOnClose} requestType="bug" />
        </>
    )
}