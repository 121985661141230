import { Card, CardBody, Skeleton, Stack, Heading, Text } from "@chakra-ui/react";

export function MunicipalityCardSkeleton() {
    return (
        <>
            {([1, 2, 3, 4, 5, 6, 7, 8, 9]).map((i) => (
                <Card key={i} minW='60'>
                    <CardBody p={3}>
                        <Skeleton minHeight={"60px"} width={"90px"}></Skeleton>
                        <Stack mt='2' spacing='2'>
                            <Skeleton><Heading size='md'>alias</Heading></Skeleton>
                            <Skeleton>
                                <Text>
                                    codemuni
                                </Text>
                            </Skeleton>
                        </Stack>
                    </CardBody>
                </Card>
            ))}
        </>
    )
}