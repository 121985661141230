import axios from 'axios';
import { Service } from 'typedi';
import { environment } from '../config/environment';
import { Token } from '../interfaces/token.interface';
import { ICredentials } from '../interfaces/credentials.interface';
import { APIError } from '../errors/api.error';
import { APIUrl } from '../utils/api-url';

@Service()
export class AuthService {

  private authToken?: Token

  constructor() {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  getGDE() {
    return this.authToken?.gde
  }

  logout() {
    this.authToken = undefined
  }

  private validateToken(): boolean {
    if (!this.authToken) return false
    const expiration = new Date(this.authToken.access_exp)
    const now = new Date()
    if (expiration < now) return false
    return true
  }

  private validateRefreshToken(): boolean {
    if (!this.authToken) return false
    const expiration = new Date(this.authToken.refresh_exp)
    const now = new Date()
    if (expiration < now) return false
    return true
  }

  public async beforeRequest() {
    const token = await this.authentificateWithRefreshToken();
    this.setToken(token)
  }

  public async authentificateWithRefreshToken(): Promise<Token> {
    if (this.validateToken()) return this.authToken as Token
    if (!this.validateRefreshToken()) throw new APIError(400, "refresh token invalid or expired")
    const res = await axios.post<Token>(APIUrl(environment.API.ENDPOINTS.refreshToken), { "refresh_token": this.authToken?.refresh_token })
    return res.data
  }

  public setToken(token: Token) {
    this.authToken = token
    axios.defaults.headers.common["Authorization"] = "Bearer " + this.authToken.access_token
  }

  async authentificateWithUser(_user: ICredentials): Promise<Token> {

    if (!_user) {
      this.logout()
      throw new APIError(400, "User not set")
    }

    if (this.validateToken()) return this.authToken as Token

    const res = await axios.post<Token>(APIUrl(environment.API.ENDPOINTS.token), { ..._user, gd: true /*gd retourne un token à accès limité qui a une longue durée d'expiration*/ })
    const authToken = res.data

    return authToken
  }

}
