import { useGOrequeteURL } from "../../../../hooks/url.hook";
import { ISessionProps } from "../../../../interfaces/session-props.interface";

export interface GOrequeteIProps extends ISessionProps { }
export default function GOrequete({ focused, municipality }: GOrequeteIProps) {

    const url = useGOrequeteURL(municipality.code)

    return (
        <>
            <iframe title={municipality.code} src={url}>
            </iframe >
        </>
    )
}