import { IsString } from "class-validator"

export class Profil {
	@IsString()
	nom: string
	@IsString()
	description: string

	constructor(nom: string, description: string) {
		this.nom = nom
		this.description = description
	}
}

export interface IProfil extends Profil { }