
import { HStack } from '@chakra-ui/react'
import { AuthService, useService } from "portail-api-client"
import { Session } from '../../../types/session.type';
import GOnet from './session/gonet.component';
import { MunicipalityCard } from './municipality-card.component';
import { MunicipalityCardSkeleton } from './municipality-card-skeletons.component';
import { useCallback, useContext } from 'react';
import { DashboardContext } from '../contexts/dashboard.context';
import { GOnetMunicipalite } from 'portail-api-models';

interface IProps {
    onClick: (s: Session) => void
}
export default function Favorites({ onClick }: IProps) {

    const api = useService(AuthService);
    const { favorites, setFavorites, ready } = useContext(DashboardContext)

    const onDelete = useCallback((m: GOnetMunicipalite) => {
        setFavorites(previous => previous.filter(p => p.model.codeMuni !== m.code && p.model.designation !== m.designation.nom))
    }, [setFavorites])

    return (
        <HStack className="dashboard-h-scroll" spacing={4} alignItems={"stretch"} overflowX={"scroll"} p={4}>
            {ready ? favorites.map(m => (
                <MunicipalityCard key={m.model.codeMuni + m.model.designation}
                    municipality={m.municipality}
                    onClick={() => onClick({ component: GOnet, props: { municipality: m.municipality, gde: api.getGDE() } })}
                    onDelete={() => onDelete(m.municipality)}
                />
            )) : <MunicipalityCardSkeleton />
            }
        </HStack>
    )
}