import { useGOnetURL } from "../../../../hooks/url.hook";
import { ISessionProps } from "../../../../interfaces/session-props.interface";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../../app.context";
import { useInitSync } from "../../../../hooks/init.hook";
import { LoadingOverlay } from "./components/loading-overlay.component";
import { ClipboardFeature } from "./components/clipboard-feature.component";
import { SessionActionContext } from "../../contexts/session-actions.context";
import { GOnetJoyride } from "./components/gonet-joyride.component";
import { SessionOffline } from "./components/session-offline.component";

export interface GOnetIProps extends ISessionProps { gde?: string, geoprojetShareId?: string }
function GOnet({ focused, municipality: { code, nom }, gde, geoprojetShareId, onClose }: GOnetIProps) {

    const { restoreSession } = useContext(AppContext).options
    const [forceRestartSession, setForceRestartSession] = useState(false)
    const [timestamp, setTimestamp] = useState(new Date())
    const url = useGOnetURL(code, { gde, geoprojetShareId, restoreSession: forceRestartSession ? false : restoreSession, timestamp })
    const ref = useRef<HTMLIFrameElement>(null)
    const { restartRequest, setRestartRequest } = useContext(SessionActionContext)
    const [ready, setReady] = useState(false);

    const iframeRender = useMemo(() => {
        console.log(code)
        return (
            <iframe className="gonet-iframe" ref={ref} title={code} src={url}>
            </iframe >
        )
    }, [code, url])

    useEffect(() => {
        if (!restartRequest || !focused) return
        setTimestamp(new Date())
        setForceRestartSession(true)
        setRestartRequest(false)
    }, [focused, restartRequest, setRestartRequest, setForceRestartSession, setTimestamp])

    useInitSync(() => {
        if (!ref?.current) return
        ref.current.onload = function (e) {
            setReady(true)
        }
    })

    return (
        <>
            <SessionOffline url={url} onClose={onClose} />
            <LoadingOverlay show={!ready} />
            {focused && <ClipboardFeature municipalityName={nom} iframe={ref} />}
            {ready && <GOnetJoyride />}
            {iframeRender}
        </>
    )
}

export default memo(GOnet)