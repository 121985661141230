import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, FormControl, FormErrorMessage, Textarea, FormLabel, Icon, Spacer } from "@chakra-ui/react";
import { ChangeEvent, RefObject, useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useService } from "portail-api-client";
import { GOnetConnector } from "../../../../../services/gonet-connector.service";
import { SessionActionContext } from "../../../contexts/session-actions.context";
import { BiPaste } from "react-icons/bi"
import { useFocus } from "../../../../../hooks/focus.hook";
import { AppContext } from "../../../../../app.context";

interface IProps { municipalityName: string, iframe: RefObject<HTMLIFrameElement> }
export function ClipboardFeature({ municipalityName, iframe }: IProps) {

    const { onClose } = useDisclosure()
    const [matricules, setMatricules] = useState(new Array<string>())
    const [lots, setLots] = useState(new Array<string>())
    const [inputText, setInputText] = useState("")
    const { clipboard, clipboardAutomatic } = useContext(AppContext).options
    const cancelRef = useRef<any>()
    const { t } = useTranslation()
    const gonetConnector = useService(GOnetConnector)
    const { manualClipboardRequest, setManualClipboardRequest } = useContext(SessionActionContext)

    const parseMatricules = useCallback((text: string) => {
        const result = gonetConnector.parseTextForMatriculesOrLots(text)
        setMatricules(result.matricules)
        setLots(result.lots)
        return result;
    }, [gonetConnector])

    const onInputChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        parseMatricules(event.target.value)
        setInputText(event.target.value)
    }, [parseMatricules])

    const openMatricules = useCallback(() => {
        if (!iframe || !iframe.current) return
        gonetConnector.openMatriculesToIFrame(matricules, lots, iframe.current)
        onClose()
        setManualClipboardRequest(false)
    }, [matricules, lots, onClose, iframe, setManualClipboardRequest, gonetConnector])

    const onCancel = useCallback(() => {
        setManualClipboardRequest(false)
        onClose()
        setMatricules([])
        setLots([])
        setInputText("")
    }, [onClose, setManualClipboardRequest, setMatricules, setLots, setInputText])

    const onPaste = useCallback(async () => {
        const text = await navigator.clipboard.readText()
        parseMatricules(text)
        setInputText(text)
    }, [parseMatricules, setInputText])

    const onFocus = useCallback(async () => {
        if (manualClipboardRequest) return
        const clipText = await navigator.clipboard.readText()
        const result = gonetConnector.parseTextForMatriculesOrLots(clipText)
        
        if (result.matricules.length <= 0 && result.lots.length <= 0) return
        if (clipboardAutomatic) {
            if (!iframe || !iframe.current) return
            gonetConnector.openMatriculesToIFrame(result.matricules, result.lots, iframe.current)
        }
        else {
            setMatricules(result.matricules)
            setLots(result.lots)
            setInputText(clipText)
            setManualClipboardRequest(true)
        }
    }, [manualClipboardRequest, setManualClipboardRequest, clipboardAutomatic, iframe, setMatricules, setLots, gonetConnector, setInputText])

    useFocus(iframe.current, onFocus, clipboard)

    return (
        <AlertDialog
            isOpen={manualClipboardRequest}
            leastDestructiveRef={cancelRef}
            onClose={onCancel}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("clipboard.manualPaste.title")}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {manualClipboardRequest && (
                            <FormControl mb={4} isInvalid={matricules.length <= 0 && lots.length <= 0}>
                                <FormLabel>{t("clipboard.manualPaste.formLabel")}</FormLabel>
                                <Textarea value={inputText} onChange={onInputChange} />
                                {inputText.length > 0 && <FormErrorMessage>{t("clipboard.manualPaste.formError")}</FormErrorMessage>}
                            </FormControl>
                        )}
                        {matricules.length > 0 && matricules.length >= lots.length &&
                            <Text>{matricules.length + t("clipboard.headerMatricules") + t("clipboard.header2") + municipalityName + "?"}</Text>
                        }
                        {lots.length > 0 && lots.length > matricules.length &&
                            <Text>
                                {lots.length + t("clipboard.headerLots") + t("clipboard.header2") + municipalityName + "?"}
                            </Text>
                        }


                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onPaste} rightIcon={<Icon as={BiPaste} />}>{t("clipboard.pasteButton")}</Button>
                        <Spacer />
                        <Button ref={cancelRef} onClick={onCancel}>
                            {t("common.cancel")}
                        </Button>
                        <Button isDisabled={matricules.length <= 0 && lots.length <= 0} colorScheme='teal' onClick={openMatricules} ml={3}>
                            {t("common.continue")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}