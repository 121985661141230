import { Alert, AlertIcon, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useDisclosure } from "@chakra-ui/react"
import { useService } from "portail-api-client"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { GOnetConnector } from "../../../../../services/gonet-connector.service"

interface IProps {
    url: string
    onClose?: () => void
}
export function SessionOffline({ url, onClose }: IProps) {

    const { t } = useTranslation()
    const gonetConnector = useService(GOnetConnector)
    const { isOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    const onCloseSession = useCallback(() => {
        onCloseModal()
        onClose && onClose()
    }, [onCloseModal, onClose])

    useEffect(() => {
        (async () => {
            const online = await gonetConnector.isOnline(url)
            if (!online) onOpenModal()
        })()
    }, [url, gonetConnector, onOpenModal])

    return (
        <Modal preserveScrollBarGap isOpen={isOpen} onClose={onCloseSession}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("sessionOffline.title")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Alert status="error">
                        <AlertIcon />
                        {t("sessionOffline.subtitle")}
                    </Alert>
                </ModalBody>

                <ModalFooter>
                    <Spacer />
                    <Button onClick={onCloseSession}>{t("common.ok")}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}