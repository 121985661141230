import { Popover, PopoverTrigger, IconButton, Icon, PopoverContent, PopoverArrow, PopoverBody, VStack, Button, useDisclosure } from "@chakra-ui/react"
import { t } from "i18next"
import { useCallback, useContext } from "react"
import { BsThreeDotsVertical } from "react-icons/bs"
import { TbTableImport } from "react-icons/tb"
import { GrRefresh } from "react-icons/gr"
import { SessionActionContext } from "../contexts/session-actions.context"


export function SessionActions() {

    const { isOpen, onClose, onOpen } = useDisclosure()
    const { setManualClipboardRequest, setRestartRequest } = useContext(SessionActionContext)

    const requestManualClipboard = useCallback(() => {
        setManualClipboardRequest(true)
        onClose()
    }, [setManualClipboardRequest, onClose])

    const requestRestart = useCallback(() => {
        setRestartRequest(true)
        onClose()
    }, [setRestartRequest, onClose])

    return (
        <>
            <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
                <PopoverTrigger>
                    <IconButton className="session-actions-button" variant={"ghost"} icon={<Icon as={BsThreeDotsVertical} />} aria-label='more'></IconButton >
                </PopoverTrigger>
                <PopoverContent >
                    <PopoverArrow />
                    <PopoverBody p={4} >
                        <VStack alignItems={"stretch"} spacing={"4"} >
                            <Button leftIcon={<Icon as={TbTableImport} />}
                                onClick={requestManualClipboard} justifyContent={"start"} >{t("dashboard.sessionActions.pasteButton")}
                            </Button>
                            <Button leftIcon={<Icon as={GrRefresh} />}
                                onClick={requestRestart} justifyContent={"start"} >{t("dashboard.sessionActions.refresh")}
                            </Button>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}