import { SetStateAction, createContext } from "react";
import { GOnetMunicipalite, SharedGeoprojet } from "portail-api-models";
import { Session } from "../../../types/session.type";
import { WithMunicipality } from "../../../interfaces/with-municipality.interface";
import { Favorite } from "../../../repositories/models/favorite.model";

interface IDashboardContext {
    ready: boolean, setReady: (value: SetStateAction<boolean>) => void,
    favorites: Array<WithMunicipality<Favorite>>, setFavorites: (value: SetStateAction<Array<WithMunicipality<Favorite>>>) => void,
    geoprojects: Array<WithMunicipality<SharedGeoprojet>>, setGeoprojects: (value: SetStateAction<Array<WithMunicipality<SharedGeoprojet>>>) => void,
    municipalities: Array<GOnetMunicipalite>, setMunicipalities: (value: SetStateAction<GOnetMunicipalite[]>) => void,
    sessions: Array<Session>, setSessions: (value: SetStateAction<Session[]>) => void
}

export const DashboardContext = createContext<IDashboardContext>({
    ready: false,
    setReady: () => { throw new Error("not initialized yet") },
    favorites: [],
    setFavorites: () => { throw new Error("not initialized yet") },
    geoprojects: [],
    setGeoprojects: () => { throw new Error("not initialized yet") },
    municipalities: [],
    setMunicipalities: () => { throw new Error("not initialized yet") },
    sessions: [],
    setSessions: () => { throw new Error("not initialized yet") }
})