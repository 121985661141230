import i18next from "i18next";
import { environment } from "../config/environment";
import { useMemo } from "react";

interface GOnetURLParams { gde?: string, geoprojetShareId?: string, restoreSession?: boolean, timestamp?: Date }
export function useGOnetURL(codemuni: string, { gde, geoprojetShareId: geoprojet, restoreSession, timestamp }: GOnetURLParams) {
    return useMemo(() => {
        const host = window.location.host.includes("localhost") ? "gonet.goazimut.com" : window.location.host
        var url = new URL("https://" + host + environment.GONET.BASE);
        url.searchParams.append(environment.GONET.PARAMETERS.CODE_MUNI, codemuni)
        url.searchParams.append(environment.GONET.PARAMETERS.PUBLIC, !!gde ? "0" : "1")
        url.searchParams.append(environment.GONET.PARAMETERS.IFRAMEMODE, "1")
        url.searchParams.append(environment.GONET.PARAMETERS.LANG, i18next.language)
        geoprojet && url.searchParams.append(environment.GONET.PARAMETERS.GEOPROJECT, geoprojet)
        gde && url.searchParams.append(environment.GONET.PARAMETERS.GDE, gde)
        url.searchParams.append(environment.GONET.PARAMETERS.RESTORE_SESSION, restoreSession ? "1" : "0")
        //this timestamp is used to reload the url in case of a restart request
        if (timestamp)
            url.searchParams.append("reloadTimestamp", timestamp.getMilliseconds().toString())
        //to test clipboard with localhost
        //return "http://localhost:3000/favicon.ico"
        return url.toString()
    }, [codemuni, gde, geoprojet, restoreSession, timestamp])
}

export function useGOrequeteURL(codemuni: string) {
    const host = window.location.host.includes("localhost") ? "gonet.goazimut.com" : window.location.host
    var url = new URL("https://" + host + environment.GOREQUETE.BASE);
    //url.searchParams.append(environment.GONET.PARAMETERS.CODE_MUNI, codemuni)
    //url.searchParams.append(environment.GONET.PARAMETERS.LANG, i18next.language)
    return url.toString()
}

export function useGOgfsURL(codemuni: string) {
    const host = window.location.host.includes("localhost") ? "gonet.goazimut.com" : window.location.host
    var url = new URL("https://" + host + environment.GOGFS.BASE);
    //url.searchParams.append(environment.GONET.PARAMETERS.CODE_MUNI, codemuni)
    //url.searchParams.append(environment.GONET.PARAMETERS.LANG, i18next.language)
    return url.toString()
}