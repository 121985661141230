export const environment = {
    API: {
        BASE: "https://portail.goazimut.com/api/",
        ENDPOINTS: {
            token: "auth/token",
            refreshToken: "auth/refreshtoken",
            municipalites: "municipalites",
            geolocate: "services/geolocation",
            partageGeoprojets: "/geoprojets/partage/"
        }
    },
    USER_REPORT: {
        SLACK_WEBHOOK_URL: "https://hooks.slack.com/services/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"
    }
}