import { CloseIcon } from "@chakra-ui/icons";
import { Popover, PopoverTrigger, IconButton, Badge, PopoverContent, PopoverArrow, PopoverBody, VStack, Card, HStack, Button, useDisclosure, Text } from "@chakra-ui/react";
import { Session } from "../../../types/session.type";
import { useCallback } from "react";
import { GetSessionTypeName } from "../../../utils/tostring";
import { useTranslation } from "react-i18next";

interface IProps { tabIndex: number, sessions: Array<Session>, onRemoveSession: (i: number) => void, onSessionSwitch: (i: number) => void }
export function SessionSwitcher({ tabIndex, sessions, onRemoveSession, onSessionSwitch }: IProps) {

    const { onOpen, onClose, isOpen } = useDisclosure()
    const { t } = useTranslation()
    const setTabOutline = useCallback((i: number) => {
        if ((i + 1) === tabIndex)
            return "2px solid purple !important"
        return "inherit"
    }, [tabIndex])

    return (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <PopoverTrigger>
                <IconButton className="session-switcher-button" variant={"ghost"} icon={
                    <Badge display={"flex"} justifyContent={"center"} alignItems={"center"} boxSize={6} colorScheme="purple" variant="outline">{sessions.length}</Badge>
                } aria-label="sessions"></IconButton>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverBody p={4}>
                    <VStack alignItems={"stretch"}>
                        {sessions.map((s, i) => (
                            <Card key={s.props.municipality.code} p={2} alignItems={"stretch"} colorScheme="purple" outline={setTabOutline(i)}>
                                <HStack>
                                    <Button className="ellipsis" px={2} onClick={() => { onSessionSwitch(i + 1); onClose() }} flexGrow={1} justifyContent={"end"} variant={"ghost"}>
                                        <VStack alignItems={"start"} gap={0}>
                                            <Text>{s.props.municipality.nom}</Text>
                                            <Text fontSize={"xs"}>{t("dashboard." + GetSessionTypeName(s))}</Text>
                                        </VStack>
                                    </Button>
                                    <IconButton onClick={() => onRemoveSession(i)} icon={<CloseIcon />} aria-label="x"></IconButton>
                                </HStack>
                            </Card>
                        ))}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}