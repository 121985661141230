import { IsString } from "class-validator"

export class Favorite {
    @IsString()
    codeMuni: string
    @IsString()
    designation: string

    constructor(codeMuni: string, designation: string) {
        this.codeMuni = codeMuni
        this.designation = designation
    }
}