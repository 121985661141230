import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import Icon from "@chakra-ui/icon"
import { Spacer } from "@chakra-ui/layout"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal"
import { Textarea } from "@chakra-ui/textarea"
import { t } from "i18next"
import { ReportService, useService } from "portail-api-client"
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react"
import { CiImport } from "react-icons/ci"
import { environment } from "../../../config/environment"
import { Collapse } from "@chakra-ui/transition"
import { Alert, AlertIcon } from "@chakra-ui/alert"
import { TokenRepository } from "../../../repositories/token.repository"

interface IProps {
    requestType: string
    isOpen: boolean
    onClose: () => any
}

export function Contact({ requestType, isOpen, onClose }: IProps) {

    const reportService = useService(ReportService)
    const [message, setMessage] = useState("")
    const [sent, setSent] = useState(false)
    const userRepository = useService(TokenRepository)

    const invalidMessage = useMemo(() => message === "", [message])

    const onSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const user = await userRepository.get()
        reportService.post(environment.USER_REPORT_APPNAME, user?.username as string, requestType, message)
        setSent(true)
        setTimeout(() => {
            setMessage("")
            setSent(false)
        }, environment.USER_REPORT_SENT_DELAY)
    }, [userRepository, reportService, requestType, message, setMessage, setSent])

    const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value)
    }, [setMessage])

    return (
        <Modal preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t(`contact.${requestType}.title`)}</ModalHeader>
                <ModalCloseButton />
                <form id="contact-form" onSubmit={onSubmit} style={{ width: "100%" }}>
                    <ModalBody>

                        <FormControl isInvalid={invalidMessage}>
                            <FormLabel >{t(`contact.${requestType}.placeholder`)}</FormLabel>
                            <Textarea disabled={sent} value={message} onChange={onChange} />
                        </FormControl>
                        <Collapse in={sent} animateOpacity>
                            <Alert status='success'>
                                <AlertIcon />
                                {t("contact.sent")}
                            </Alert>
                        </Collapse>

                    </ModalBody>

                    <ModalFooter>
                        <Button id="cancel-contact" onClick={onClose} colorScheme='teal' variant='outline'>
                            {t("common.cancel")}
                        </Button>
                        <Spacer />
                        <Button isDisabled={invalidMessage || sent} type="submit" leftIcon={<Icon as={CiImport} />} colorScheme='teal' variant='solid'>
                            {t("common.send")}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal >
    )
}