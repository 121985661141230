import { IconButton, Icon, Select, Text, Divider, VStack, Switch, HStack, useToast, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Alert, AlertIcon } from "@chakra-ui/react";
import i18next from "i18next";
import { useCallback, ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";
import { AppContext } from "../../../app.context";
import { environment } from "../../../config/environment";
import { DashboardActions } from "./dashboard-actions.component";
import { useRoutingDisclosure } from "../../../hooks/routing-disclosure.hook";

export function Options() {

    const { t } = useTranslation();

    const { setOptions, setLanguage, featureSupport } = useContext(AppContext)
    const { clipboard, clipboardAutomatic, duplicateTabs, restoreSession } = useContext(AppContext).options
    const { isOpen, onOpen, onClose } = useRoutingDisclosure("features")

    const toast = useToast()

    const changeClipboard = useCallback(async () => {
        try {
            const cool = await navigator.clipboard.read()
            console.log(cool)
        }
        catch (e) {
            toast.closeAll()
            return toast({
                title: t("permissions.errors.permission"),
                description: t("permissions.errors.clipboard"),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        }
        setOptions(o => ({ ...o, clipboard: !o.clipboard }))
    }, [toast, setOptions, t])

    const changeClipboardAutomatic = useCallback(async () => {
        setOptions(o => ({ ...o, clipboardAutomatic: !o.clipboardAutomatic }))
    }, [setOptions])

    const changeDuplicateTabs = useCallback(async () => {
        setOptions(o => ({ ...o, duplicateTabs: !o.duplicateTabs }))
    }, [setOptions])

    const changeRestoreSession = useCallback(async () => {
        setOptions(o => ({ ...o, restoreSession: !o.restoreSession }))
    }, [setOptions])

    const changeLanguage = useCallback(async (e: ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value)
    }, [setLanguage])

    return (
        <HStack>

            <IconButton
                aria-label='Profile'
                fontSize={"2xl"}
                background={"transparent"}
                icon={<Icon as={FaCog} />}
                onClick={onOpen}
            />
            <DashboardActions />

            <Modal preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("dashboard.profile.header")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} alignItems={"start"}>
                            <Select onChange={changeLanguage} defaultValue={i18next.language}>
                                <option value='fr'>{t("dashboard.profile.fr")}</option>
                                <option value='en'>{t("dashboard.profile.en")}</option>
                            </Select>
                            <Divider orientation='horizontal' />
                            <Text fontWeight={"bold"}>{t("dashboard.profile.featuresHeader")}</Text>
                            <VStack alignItems={"start"}>
                                <HStack spacing={4} alignItems={"start"}>
                                    <Switch disabled={!featureSupport.clipboard} isChecked={clipboard} onChange={changeClipboard}></Switch>
                                    <Text>{t("dashboard.profile.clipboardTitle")}</Text>
                                </HStack>
                                {!featureSupport.clipboard &&
                                    <Alert status='error'>
                                        <AlertIcon />
                                        {t("dashboard.profile.browserSupportNo")}
                                    </Alert>
                                }
                                <Text fontSize={"sm"} fontWeight={"thin"}>{t("dashboard.profile.clipboard")}</Text>
                            </VStack>
                            <VStack alignItems={"start"}>
                                <HStack spacing={4} alignItems={"start"}>
                                    <Switch disabled={!clipboard} isChecked={clipboardAutomatic} onChange={changeClipboardAutomatic}></Switch>
                                    <Text>{t("dashboard.profile.clipboardAutomaticTitle")}</Text>
                                </HStack>
                                <Text fontSize={"sm"} fontWeight={"thin"}>{t("dashboard.profile.clipboardAutomatic")}</Text>
                            </VStack>
                            <VStack alignItems={"start"}>
                                <HStack spacing={4} alignItems={"start"}>
                                    <Switch isChecked={duplicateTabs} onChange={changeDuplicateTabs}></Switch>
                                    <Text>{t("dashboard.profile.duplicateTabsTitle")}</Text>
                                </HStack>
                                <Text fontSize={"sm"} fontWeight={"thin"}>{t("dashboard.profile.duplicateTabs")}</Text>
                            </VStack>
                            <VStack alignItems={"start"}>
                                <HStack spacing={4} alignItems={"start"}>
                                    <Switch isChecked={restoreSession} onChange={changeRestoreSession}></Switch>
                                    <Text>{t("dashboard.profile.restoreSessionTitle")}</Text>
                                </HStack>
                                <Text fontSize={"sm"} fontWeight={"thin"}>{t("dashboard.profile.restoreSession")}</Text>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Text>v{environment.VERSION}</Text>
                        <Spacer />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </HStack >
    )
}