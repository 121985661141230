import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    common: {
                        install: "Install",
                        cancel: "Cancel",
                        continue: "Continue",
                        matricules: "Matricules",
                        send: "Send",
                        lots: "Lots",
                        update: "Update",
                        import: "Import",
                        skip: "Skip",
                        next: "Next",
                        done: "Done",
                        back: "Back",
                        close: "Close",
                        last: "Done",
                        open: "Open",
                        retry: "Retry",
                        ok: "Ok"
                    },
                    permissions: {
                        errors: {
                            "permission": "Permission error",
                            "clipboard": "The clipboard permission is required for this feature.",
                            "geolocation": "The geolocation permission is required for this feature."
                        }
                    },
                    login: {
                        "username": "Username",
                        "password": "Password",
                        "usernameError": "Username required",
                        "passwordError": "Password required",
                        "login": "Login",
                        "loginErrors": {
                            "400": { title: "Invalid request", subtitle: "Please try again later, GOAzimut servers might be under maintenance." },
                            "404": { title: "Our server is unavailable", subtitle: "Please try again later, GOAzimut servers might be under maintenance." },
                            "401": { title: "Invalid credentials", subtitle: "Invalid username or password." },
                            "403": { title: "Access revoked", subtitle: "Your access rights have been removed. Please contact your administrator." },
                            "ERR_NETWORK": { title: "Internet not available", subtitle: "Do you have an active internet connection?" },
                        },
                    },
                    dashboard: {
                        "municipalitiesHeader": "Municipalities",
                        "search": "Search",
                        "favorites": "Recents",
                        geoprojects: "Geoprojects",
                        importGeoprojectButton: "Import a geoproject",
                        "municipalities": "Your municipalities",
                        "installHeader": "You can install GOnet on your device!",
                        "updateHeader": "A new version is available!",
                        openWith: "Open in...",
                        gonetpublic: "GOnet public",
                        gonetgeoprojet: "GOnet geoproject",
                        gonet: "GOnet private",
                        gonetdefault: "(by default)",
                        gorequete: "GOrequête",
                        gogfs: "GOgfs",
                        in: "in",
                        profile: {
                            "header": "Settings",
                            "fr": "Français",
                            "en": "English",
                            logout: "Logout",
                            browserSupportNo: "Your browser does not support this feature. Please use Chrome to enable this feature. You can paste manually using the 3-dots button on the topbar while in a GOnet session.",
                            featuresHeader: "Features",
                            clipboardTitle: "Clipboard",
                            clipboard: "When you focus on this app while being in a GOnet session, your clipboard will be parsed to find any matricules. A notification will appear to ask if you want to open them.",
                            clipboardAutomaticTitle: "Automatic clipboard",
                            clipboardAutomatic: "Skip the notification and always open automatically matricules from your clipboard",
                            duplicateTabsTitle: "Duplicate tabs",
                            duplicateTabs: "Open as many tabs as you want for the same municipality",
                            restoreSessionTitle: "Restore GOnet sessions",
                            restoreSession: "Automatically restore the state of your last session (selections, searches, etc) when opening a GOnet instance",
                            geolocationTitle: "Geolocation",
                            geolocation: "Enable the geolocate button to use your current position to open a municipality",
                        },
                        importgeoproject: {
                            header: "Import a geoproject",
                            input: "Geoproject URL",
                            errors: {
                                invalidUrl: "Invalid Geoproject",
                                "204": { title: "Oops!", subtitle: "This geoproject doesn't seem to exist anymore" },
                                "403": { title: "Oops!", subtitle: "You do not have access to the municipality " },
                                "500": { title: "Oops!", subtitle: "An error occured during the import. The link might be expired." }
                            }
                        },
                        errors: {
                            "403": { title: "Oops!", subtitle: "You do not have access to the municipality of " },
                            "404": { title: "Oops!", subtitle: " is not served by GOnet" },
                            offline: { title: "Oops! You seem to be offline", subtitle: "An internet connexion is required to use GOnet6" }
                        },
                        "sessionActions": {
                            "pasteButton": "Paste matricules/lots",
                            "refresh": "Reset"
                        }
                    },
                    clipboard: {
                        pasteButton: "Paste",
                        headerMatricules: " matricules detected. ",
                        headerLots: " lots detected. ",
                        header2: "Do you want to open them in ",
                        manualPaste: {
                            title: "Open matricules/lots",
                            formLabel: "Write below matricules/lots in any format",
                            formError: "No matricules or lots detected"
                        }
                    },
                    designations: {
                        "Municipalité": "Municipality",
                        "Territoire non organisé": "Unorganised territory",
                        "Ville": "Town",
                        "Canton": "Canton",
                        "Village": "Village",
                        "Paroisse": "Parish"
                    },
                    contact: {
                        feature: {
                            title: "Request a feature",
                            placeholder: "Please describe as best as you can the feature you want to request"
                        },
                        bug: {
                            title: "Report a problem",
                            placeholder: "Please describe as best as you can the problem you encountered"
                        },
                        sent: "Your report was sent successfully. It will be processed by our team in the following days."
                    },
                    onboarding: {
                        "whatis": "What is GOnet app?",
                        slide1: {
                            header: "All in the same place",
                            subheader: "Consult all the municipalities to which you have access"
                        },
                        slide2: {
                            header: "Personalized",
                            subheader: "Fast access to the municipalities you use the most"
                        },
                        slide3: {
                            header: "From clipboard to GOnet6",
                            subheader: "Perform a selection in GOnet from matricules or lots copied from another application."
                        },
                        slide4: {
                            header: "Portal to the GOazimut ecosystem (coming soon)",
                            subheader: "Access to AZIMUT various solutions (GOnet, GOrequête, GOgfs) from a single application"
                        },
                        slide5: {
                            header: "Easy access",
                            subheader: "Install it as an app on any devices"
                        },
                        joyride: {
                            search: "Search through your municipalities",
                            geolocate: "Use geolocation to access the municipality where you are located",
                            install: "Install the app on your device to have even quicker access to GOnet",
                            sessionSwitcher: "Switch from a municipality to another",
                            sessionActions: "Actions relative to your session such as reinitialize or select a list of matricules or lots",
                            gonetIframe: "Use GOnet6 as you would usually do!"
                        },
                    },
                    iframeMessaging: {
                        noResult: {
                            title: "Oops!",
                            subtitle: "No results were found from your request"
                        }
                    },
                    sessionOffline: {
                        title: "Oops!",
                        subtitle: "An internet connection is required to use GOnet."
                    }
                }
            },
            fr: {
                translation: {
                    common: {
                        install: "Installer",
                        cancel: "Annuler",
                        continue: "Continuer",
                        send: "Envoyer",
                        matricules: "Matricules",
                        lots: "Lots",
                        update: "Mettre à jour",
                        import: "Importer",
                        skip: "Sauter",
                        next: "Suivant",
                        done: "Terminer",
                        back: "Retour",
                        close: "Fermer",
                        last: "Terminer",
                        open: "Ouvrir",
                        retry: "Réessayer",
                        ok: "Ok"
                    },
                    permissions: {
                        errors: {
                            "permission": "Erreur de permission",
                            "clipboard": "L'accès au presse-papier est requis pour cette fonctionnalité.",
                            "geolocation": "L'accès à la géolocalisation est requis pour cette fonctionnalité."
                        }
                    },
                    login: {
                        "username": "Nom d'utilisateur",
                        "password": "Mot de passe",
                        "usernameError": "Nom d'utilisateur requis",
                        "passwordError": "Mot de passe requis",
                        "login": "Se connecter",
                        "loginErrors": {
                            "400": { title: "Requête invalide", subtitle: "Svp veuillez réessayer plus tard, les serveurs GOAzimut sont peut-être en maintenance." },
                            "404": { title: "Notre serveur est indisponible", subtitle: "Svp veuillez réessayer plus tard, les serveurs GOAzimut sont peut-être en maintenance." },
                            "401": { title: "Identification invalide", subtitle: "Nom d'utilisateur ou mot de passe invalide." },
                            "403": { title: "Accès interdit", subtitle: "Vos accès ont été désactivés. Veuillez contacter votre administrateur." },
                            "ERR_NETWORK": { title: "Connexion internet non disponible", subtitle: "Avez-vous une connexion internet active?" },
                        }
                    },
                    dashboard: {
                        "municipalitiesHeader": "Municipalités",
                        "search": "Rechercher",
                        "favorites": "Récents",
                        geoprojects: "Géoprojets",
                        importGeoprojectButton: "Importer un géoprojet",
                        "municipalities": "Vos municipalités",
                        "installHeader": "Vous pouvez installer GOnet sur votre appareil!",
                        "updateHeader": "Une nouvelle version est disponible!",
                        openWith: "Ouvrir dans...",
                        gonetpublic: "GOnet publique",
                        gonetgeoprojet: "Geoprojet GOnet",
                        gonet: "GOnet privé",
                        gorequete: "GOrequête",
                        gogfs: "GOgfs",
                        gonetdefault: "(par défaut)",
                        in: "dans",
                        profile: {
                            "header": "Paramètres",
                            "fr": "Français",
                            "en": "English",
                            logout: "Se deconnecter",
                            featuresHeader: "Fonctionnalités",
                            browserSupportNo: "Votre fureteur ne supporte pas cette fonctionnalité. Veuillez utiliser Chrome pour pouvoir activer cette fonctionnalité. Vous pouvez coller vos matricules manuellement en utilisant le bouton 3-points dans la barre du haut lorsque vous êtes dans une session GOnet.",
                            clipboardTitle: "Presse-papier",
                            clipboard: "Lorsque vous faites le focus sur l'application et que vous êtes dans une session GOnet, votre presse-papier sera analysé pour trouver les matricules. Une notification apparaîtra pour vous demander si vous voulez les ouvrir.",
                            clipboardAutomaticTitle: "Presse-papier automatique",
                            clipboardAutomatic: "Saute la notification et ouvre automatiquement les matricules trouvés dans votre presse-papier.",
                            geolocationTitle: "Geolocation",
                            geolocation: "Active le bouton de géolocalisation qui utilise votre position pour ouvrir la municipalité dans laquelle vous êtes située.",
                            duplicateTabsTitle: "Duplicata de sessions",
                            duplicateTabs: "Ouvrez autant de sessions que vous désirez pour la même municipalité.",
                            restoreSessionTitle: "Restorer les sessions GOnet",
                            restoreSession: "Restore automatiquement l'état de la dernière session (sélections, recherches, etc) lors de l'ouverture d'une nouvelle instance GOnet",
                        },
                        importgeoproject: {
                            header: "Importer un géoprojet",
                            input: "URL du géoprojet",
                            errors: {
                                invalidUrl: "Géoprojet invalide",
                                "204": { title: "Oups!", subtitle: "Ce géoprojet ne semble plus exister" },
                                "403": { title: "Oups!", subtitle: "Vous n'avez pas accès à la municipalité " },
                                "500": { title: "Oups!", subtitle: "Une erreur est survenue pendant l'import. Le lien est peut-être expiré." }
                            }
                        },
                        errors: {
                            "403": { title: "Oups!", subtitle: "Vous n'avez pas accès à la municipalité de " },
                            "404": { title: "Oups!", subtitle: " n'est pas desservi par GOnet." }
                        },
                        "sessionActions": {
                            "pasteButton": "Coller matricules/lots",
                            "refresh": "Réinitialiser"
                        }
                    },
                    clipboard: {
                        pasteButton: "Coller",
                        headerMatricules: " matricules detectés. ",
                        headerLots: " lots detectés. ",
                        header2: "Voulez-vous les ouvrir dans ",
                        manualPaste: {
                            title: "Ouvrir des matricules/lots",
                            formLabel: "Écrire ci-bas dans n'importe quel format vos matricules/lots",
                            formError: "Aucun matricules ou lots détectés"
                        }
                    },
                    designations: {
                        "Municipalité": "Municipalité",
                        "Territoire non organisé": "Territoire non organisé",
                        "Ville": "Ville",
                        "Canton": "Canton",
                        "Village": "Village",
                        "Paroisse": "Paroisse"
                    },
                    contact: {
                        feature: {
                            title: "Demander une fonctionnalité",
                            placeholder: "Décrivez du mieux que vous pouvez la fonctionnalité demandée"
                        },
                        bug: {
                            title: "Signaler un problème",
                            placeholder: "Décrivez du mieux que vous pouvez le problème que vous avez rencontré"
                        },
                        sent: "Votre rapport à été envoyé avec succès. Il sera traité dans les jours qui suivent."
                    },
                    onboarding: {
                        "whatis": "Qu’est-ce l'application GOnet?",
                        slide1: {
                            header: "Tout au même endroit",
                            subheader: "Survolez toutes les municipalités auxquelles vous avez accès à partir de la page d’accueil"
                        },
                        slide2: {
                            header: "Personnalisé",
                            subheader: "Accédez rapidement aux municipalités que vous utilisez le plus fréquemment"
                        },
                        slide3: {
                            header: "Du presse-papier à GOnet6",
                            subheader: "Effectuez une sélection dans GOnet à partir de matricules ou de lots copiés d’une autre application. "
                        },
                        slide4: {
                            header: "Portail vers l'écosystème GOazimut (à venir)",
                            subheader: "Accédez aux différentes solutions AZIMUT (GOnet, GOrequête, GOgfs) à partir d’une seule application."
                        },
                        slide5: {
                            header: "Accès rapide",
                            subheader: "Installez en tant qu'app sur tous vos appareils"
                        },
                        joyride: {
                            search: "Cherchez parmi vos municipalités",
                            geolocate: "Utilisez la géolocalisation pour accéder à la municipalité où vous êtes située",
                            install: "Installez l'application sur votre appareil pour un accès encore plus rapide",
                            sessionSwitcher: "Passez d'une municipalité à une autre.",
                            sessionActions: "Actions relatives à votre session tel que réinitialiser ou sélectionner une liste de matricules ou de lots.",
                            gonetIframe: "Utilisez GOnet comme vous le feriez d'habitude!"
                        },
                    },
                    iframeMessaging: {
                        noResult: {
                            title: "Oups!",
                            subtitle: "Aucun résultat trouvé pour votre requête"
                        }
                    },
                    sessionOffline: {
                        title: "Oups!",
                        subtitle: "Une connexion internet est requise pour utiliser GOnet."
                    }
                }
            }
        },
        lng: "fr", // if you're using a language detector, do not define the lng option
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });
