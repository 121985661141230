import { IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { GOnetMunicipalite } from "./gonet-municipalite.model";
import { Municipalite } from "./municipalite.model";
import { Service } from "./service.model";

export class GeolocationResult {
	@IsNumber()
	x: number
	@IsNumber()
	y: number
	@IsNumber()
	sr: number
	@IsOptional()
	@ValidateNested()
	municipalite?: Municipalite | GOnetMunicipalite
	@IsOptional()
	@ValidateNested()
	service?: Service
	@IsOptional()
	@IsString()
	matricule?: string
	@IsOptional()
	@IsString()
	gde?: string

	constructor(
		x: number,
		y: number,
		sr: number,
		municipalite?: Municipalite | GOnetMunicipalite,
		service?: Service,
		matricule?: string,
		gde?: string
	) {
		this.x = x
		this.y = y
		this.sr = sr
		this.municipalite = municipalite
		this.service = service
		this.matricule = matricule
		this.gde = gde
	}
}

export interface IGeolocationResult extends GeolocationResult { }