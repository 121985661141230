import { plainToInstance } from 'class-transformer';
import { validate } from 'class-validator';
import { get as _get, set as _set } from 'idb-keyval';

export abstract class Repository<T> {

    abstract readonly clazz?: new (...args: any[]) => any
    abstract readonly key: string

    async validateObject(model: any) {
        const errors = await validate(model as any)
        return errors
    }

    async validateArray(models: any[]) {
        const errors = (await Promise.all(models.map(m => validate(m)))).flat()
        return errors
    }

    async get(): Promise<T | undefined> {
        try {
            const data = await _get<T>(this.key);
            if (this.clazz && data) {
                const models: any = plainToInstance(this.clazz, data)
                const errors = await (Array.isArray(models) ? this.validateArray(models) : this.validateObject(models))
                if (errors.length > 0) throw new Error(JSON.stringify(errors))
                return models as T
            }
            return data
        } catch (e) {
            console.error(e)
            return undefined
        }
    }

    async set(value: T) {
        try {
            await _set(this.key, value);
        } catch (e) {
            console.error(e)
        }
    }

    async delete() {
        try {
            await _set(this.key, undefined);
        } catch (e) {
            console.error(e)
        }
    }
}