import { useEffect, useRef } from "react";

export function useInit(funktion: () => void | Promise<void>, afterInit?: () => void | Promise<void>) {
    const isCalledRef = useRef(false);

    useEffect(() => {
        if (isCalledRef.current) return
        (async () => {
            await funktion()
            if (afterInit)
                setTimeout(() => afterInit(), 500)
        })()
        isCalledRef.current = true;
        // eslint-disable-next-line
    }, [])
}

export function useInitSync(funktion: () => ((() => void) | void), afterInit?: () => void) {
    useEffect(() => {
        const cleanup = funktion()
        if (afterInit)
            setTimeout(() => afterInit(), 500)
        return cleanup
        // eslint-disable-next-line
    }, [])
}