import { GOnetMunicipalite } from "portail-api-models"

export class WithMunicipality<T>{
    model: T
    municipality: GOnetMunicipalite

    constructor(model: T, municipality: GOnetMunicipalite) {
        this.model = model
        this.municipality = municipality
    }
}