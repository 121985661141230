import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator"
import { Municipalite } from "./municipalite.model"
import { DesignationMunicipale } from "./designation-municipale.model"
import { Region } from "./region.model"
import { MRC } from "./mrc.model"

class Population {
    @IsNumber()
    total: number
    @IsNumber()
    annee: number

    constructor(total: number, annee: number) {
        this.total = total
        this.annee = annee
    }
}

class Services {
    @IsBoolean()
    gonetpublic: boolean
    @IsBoolean()
    gonetprive: boolean
    @IsOptional()
    @IsBoolean()
    gogfs?: boolean
    @IsOptional()
    @IsBoolean()
    gorequete?: boolean

    constructor(
        gonetpublic: boolean,
        gonetprive: boolean,
        gogfs?: boolean,
        gorequete?: boolean
    ) {
        this.gonetpublic = gonetpublic
        this.gonetprive = gonetprive
        this.gogfs = gogfs
        this.gorequete = gorequete
    }
}

export class GOnetMunicipalite extends Municipalite {
    @IsNumber()
    fuseau: number
    @IsString()
    groupe: string
    @ValidateNested()
    population: Population
    @IsString()
    sql: string
    @IsString()
    logo: string
    @IsString()
    url: string
    @IsString()
    maj: string
    @ValidateNested()
    services: Services

    constructor(
        code: string,
        nom: string,
        designation: DesignationMunicipale,
        mrc: MRC,
        region: Region,
        fuseau: number,
        groupe: string,
        population: Population,
        sql: string,
        logo: string,
        url: string,
        maj: string,
        services: Services
    ) {
        super(code, nom, designation, mrc, region)
        this.fuseau = fuseau
        this.groupe = groupe
        this.population = population
        this.sql = sql
        this.logo = logo
        this.url = url
        this.maj = maj
        this.services = services
    }
}

export interface IGOnetMunicipalite extends GOnetMunicipalite { }