import { IsString } from "class-validator"

export class Service {
    @IsString()
    nom: string
    @IsString()
    alias: string
    @IsString()
    type: string
    @IsString()
    matriceUELayer: string
    @IsString()
    matriceUEFieldMat: string

    constructor(
        nom: string,
        alias: string,
        type: string,
        matriceUELayer: string,
        matriceUEFieldMat: string
    ) {
        this.nom = nom
        this.alias = alias
        this.type = type
        this.matriceUELayer = matriceUELayer
        this.matriceUEFieldMat = matriceUEFieldMat
    }
}

export interface IService extends Service { }