import { HStack } from "@chakra-ui/react";
import GOnet from "./session/gonet.component";
import { MunicipalityCardSkeleton } from "./municipality-card-skeletons.component";
import { MunicipalityCard } from "./municipality-card.component";
import { Session } from "../../../types/session.type";
import { AuthService, useService } from "portail-api-client";
import { useCallback, useContext } from "react";
import { DashboardContext } from "../contexts/dashboard.context";
import { GOnetMunicipalite, SharedGeoprojet } from "portail-api-models";
import { WithMunicipality } from "../../../interfaces/with-municipality.interface";

interface IProps {
    onClick: (s: Session) => void
}
export function Geoprojects({ onClick }: IProps) {

    const api = useService(AuthService);
    const { geoprojects, setGeoprojects, ready } = useContext(DashboardContext)

    const onDelete = useCallback((m: WithMunicipality<SharedGeoprojet>) => {
        setGeoprojects(prev => prev.filter(p => p.model.id !== m.model.id))
    }, [setGeoprojects])


    return (
        <HStack  className="dashboard-h-scroll" spacing={4} alignItems={"stretch"} overflowX={"scroll"} p={4}>
            {ready ? geoprojects.map(geoprojet => (
                <MunicipalityCard key={geoprojet.model.id} municipality={geoprojet.municipality} subtext={geoprojet.model.geoprojet.nom} onClick={() => onClick({ component: GOnet, props: { municipality: geoprojet.municipality as GOnetMunicipalite, gde: api.getGDE(), geoprojetShareId: geoprojet.model.id } })} onDelete={() => onDelete(geoprojet)} />
            )) : <MunicipalityCardSkeleton />
            }
        </HStack>
    )
}