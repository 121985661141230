import GOgfs from "../pages/dashboard/components/session/gogfs.component";
import GOnet, { GOnetIProps } from "../pages/dashboard/components/session/gonet.component";
import GOrequete from "../pages/dashboard/components/session/gorequete.component";
import { Session } from "../types/session.type";

export function GetSessionTypeName(session: Session) {
    if (session.component === GOnet) {
        const props = session.props as GOnetIProps
        if (props.geoprojetShareId)
            return "gonetgeoprojet"
        return "gonet" + (!!!props.gde ? "public" : "")
    }
    else if (session.component === GOrequete) return "gorequete"
    else if (session.component === GOgfs) return "gogfs"
    return ""
}