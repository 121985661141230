import { SetStateAction, createContext } from "react";

interface IDashboardContext {
    restartRequest: boolean, setRestartRequest: (value: SetStateAction<boolean>) => void,
    manualClipboardRequest: boolean, setManualClipboardRequest: (value: SetStateAction<boolean>) => void
}

export const SessionActionContext = createContext<IDashboardContext>({
    manualClipboardRequest: false,
    setManualClipboardRequest: () => { throw new Error("not initialized yet") },
    restartRequest: false,
    setRestartRequest: () => { throw new Error("not initialized yet") }
})