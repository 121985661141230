import { Service } from "typedi";
import { environment } from "../config/environment";
import { IIFrameMessage } from "../interfaces/iframe-message.interface";
import axios from "axios";

@Service()
export class GOnetConnector {

    registeredFunction?: (event: MessageEvent) => void

    parseTextForMatriculesOrLots(text: string): { lots: Array<string>, matricules: Array<string> } {
        if (!text || text.length === 0 || !text.match(/\d/)) return { matricules: [], lots: [] }
        const normalizedText = text.replaceAll("\n", ",").replaceAll(";", ",").replaceAll("-", "").replaceAll(", ", ",").replaceAll(" ", ",")
        const clipItems = normalizedText.split(",").filter(t => t.match(/\d{7}/));
        const matricules = clipItems.filter(i => i.length === 10 || i.length === 18);
        const lots = clipItems.filter(i => i.length === 7);
        return { matricules, lots }
    }

    openMatriculesToIFrame(matricules: Array<string>, lots: Array<string>, iframe: HTMLIFrameElement) {
        const payload = (matricules.length > lots.length) ? matricules : lots
        const msg = { type: environment.GONET.IFRAME_MESSAGING.COMMANDS.OPEN_MATRICULES, payload } as IIFrameMessage
        console.log("message sent to gonet: " + JSON.stringify(msg))
        iframe.contentWindow?.postMessage(msg)
    }

    listen(callback: () => void) {
        this.registeredFunction = (event: MessageEvent) => {
            const data = event.data as IIFrameMessage
            if (data?.type !== environment.GONET.IFRAME_MESSAGING.COMMANDS.OPEN_MATRICULES_RESPONSE) return
            if (data?.payload.length !== 0) return
            callback()
        }
        window.addEventListener("message", this.registeredFunction)
    }

    async isOnline(url: string) {
        try {
            await axios.head(url)
            return true
        } catch (e) {
            return false
        }
    }

    stopListening() {
        if (!this.registeredFunction) return
        window.removeEventListener("message", this.registeredFunction)
        this.registeredFunction = undefined
    }

}