import { Select } from "@chakra-ui/react"
import i18next, { t } from "i18next"
import { useCallback, ChangeEvent, useContext } from "react"
import { AppContext } from "../../../app.context"

export function LanguageSwitcher() {

    const { setLanguage } = useContext(AppContext)

    const changeLanguage = useCallback(async (e: ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value)
    }, [setLanguage])

    return (
        <Select onChange={changeLanguage} defaultValue={i18next.language} >
            <option value='fr' > {t("dashboard.profile.fr")} </option>
            <option value='en' > {t("dashboard.profile.en")} </option>
        </Select>
    )
}