import { SetStateAction, createContext } from "react";
import { IOptions } from "./interfaces/options.interface";
import { IFeatureSupport } from "./interfaces/feature-support.interface";

interface IAppContext {
    installed: boolean,
    setInstalled: (value: SetStateAction<boolean>) => void
    authentified: boolean,
    setAuthentified: (value: SetStateAction<boolean>) => void,
    install: () => void
    options: IOptions
    setOptions: (value: SetStateAction<IOptions>) => void,
    setLanguage: (value: string) => void,
    featureSupport: IFeatureSupport
}
export const AppContext = createContext<IAppContext>({
    installed: false,
    setInstalled: () => { throw new Error("not initialized yet") },
    authentified: false,
    setAuthentified: () => { throw new Error("not initialized yet") },
    install: () => { throw new Error("not initialized yet") },
    options: { clipboard: false, clipboardAutomatic: false, duplicateTabs: false, restoreSession: true },
    setOptions: () => { throw new Error("not initialized yet") },
    setLanguage: () => { throw new Error("not initialized yet") },
    featureSupport: { clipboard: false }
})