import { useGOgfsURL } from "../../../../hooks/url.hook";
import { ISessionProps } from "../../../../interfaces/session-props.interface";

export interface GOgfsIProps extends ISessionProps { }
export default function GOgfs({ focused, municipality }: GOgfsIProps) {

    const url = useGOgfsURL(municipality.code)

    return (
        <>
            <iframe title={municipality.code} src={url}>
            </iframe >
        </>
    )
}