import { IsOptional, IsString } from "class-validator"

export class DesignationMunicipale {
    @IsString()
    code: string
    @IsString()
    nom: string
    @IsOptional()
    @IsString()
    article?: string

    constructor(code: string, nom: string, article?: string) {
        this.code = code
        this.nom = nom
        this.article = article
    }
}

export interface IDesignationMunicipale extends DesignationMunicipale { }
