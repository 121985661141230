import { Service } from "typedi";
import { AuthService } from "./auth.service";
import axios from "axios";
import { ISharedGeoprojet, SharedGeoprojet } from "portail-api-models";
import { environment } from "../config/environment";
import { APIUrl } from "../utils/api-url";
import { plainToClass } from "class-transformer"

@Service()
export class GeoprojetPartageService {

    constructor(@Service() private auth: AuthService) { }

    async get(shareId: string): Promise<SharedGeoprojet> {
        await this.auth.beforeRequest()
        const res = await axios.get<ISharedGeoprojet>(APIUrl(environment.API.ENDPOINTS.partageGeoprojets, shareId))
        const clazzed = plainToClass(SharedGeoprojet, res.data)
        return clazzed
    }

}