import { IconButton, Icon, useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { HiLocationMarker } from "react-icons/hi";
import { Session } from "../../../types/session.type";
import GOnet from "./session/gonet.component";
import { ServicesService, useService } from "portail-api-client";

interface IProps { onGeolocated: (s: Session) => void }
export function GeolocateMe({ onGeolocated }: IProps) {

    const toast = useToast()
    const { t } = useTranslation()
    const services = useService(ServicesService)

    const geolocate = useCallback(async () => {
        try {
            const pos = await new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation.getCurrentPosition((pos) => resolve(pos), (err) => reject(err)))
            const geolocated = await services.geolocate(pos.coords.longitude, pos.coords.latitude)
            //const geolocated = await services.geolocate(-72.6472103, 45.3103455)
            //const geolocated = await services.geolocate(-72.0380366, 45.4120341)
            if (!geolocated.service) {
                toast.closeAll()
                return toast({
                    title: t("dashboard.errors.404.title"),
                    description: geolocated.municipalite?.nom + t("dashboard.errors.404.subtitle"),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: "top"
                })
            }
            if (!geolocated.gde) {
                toast.closeAll()
                return toast({
                    title: t("dashboard.errors.403.title"),
                    description: t("dashboard.errors.403.subtitle") + geolocated.municipalite?.nom,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: "top"
                })
            }
            const session = { component: GOnet, props: { municipality: geolocated.municipalite, gde: geolocated.gde } } as Session
            onGeolocated(session)
        }
        catch (e) {
            if (e instanceof GeolocationPositionError) {
                toast.closeAll()
                return toast({
                    title: t("permissions.errors.permission"),
                    description: t("permissions.errors.geolocation"),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: "top"
                })
            }
            else throw e
        }

    }, [toast, t, onGeolocated, services])

    return (
        <IconButton id="geolocation-button" className="darker-outline" variant='outline' onClick={geolocate} icon={<Icon boxSize={6} as={HiLocationMarker} />} aria-label='geolocate' />
    )
}