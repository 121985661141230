import { IsOptional, IsString } from "class-validator"

export class Utilisateur {
	@IsString()
	id: string
	@IsString()
	nom: string
	@IsString()
	email: string
	@IsString()
	description: string
	@IsOptional()
	@IsString()
	telephone?: string
	@IsOptional()
	@IsString()
	ext?: string

	constructor(
		id: string,
		nom: string,
		email: string,
		description: string,
		telephone?: string,
		ext?: string
	) {
		this.id = id
		this.nom = nom
		this.email = email
		this.description = description
		this.telephone = telephone
		this.ext = ext
	}
}

export interface IUtilisateur extends Utilisateur { }