import { Box, Button, Container, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Image, Input, Spacer, Spinner, VStack, useToast } from "@chakra-ui/react";
import azilogo from "../../assets/goazimut.webp"
import { useNavigate } from "react-router-dom";
import { FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AuthService, useService, ICredentials } from "portail-api-client";
import { AxiosError } from "axios";
import { TokenRepository } from "../../repositories/token.repository";
import { MdInstallDesktop } from "react-icons/md"
import { AppContext } from "../../app.context";
import { INSTALL } from "../../pwa-install";
import { useRoutingDisclosure } from "../../hooks/routing-disclosure.hook";
import { useInit } from "../../hooks/init.hook";
import { OnbaordingRepository } from "../../repositories/onboarding.repository";
import { OnboardingSlider } from "./components/onboarding/onboarding-slider.component";
import "./login.page.css"
import { LanguageSwitcher } from "./components/language-switer.component";

interface IProps {
    ready: boolean
}
export function LoginPage({ ready }: IProps) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useService(AuthService)
    const tokenRepository = useService(TokenRepository)
    const onboardingRepository = useService(OnbaordingRepository)
    const { isOpen, onOpen, onClose } = useRoutingDisclosure("onboarding")
    const app = useContext(AppContext)

    const [dirty, setDirty] = useState(false)

    const [usernameInput, setUsernameInput] = useState('')
    const handleEmailInputChange = (e: any) => setUsernameInput(e.target.value)
    const isUsernameError = usernameInput === '' && dirty

    const [passwordInput, setPasswordInput] = useState('')
    const handlePasswordInputChange = (e: any) => setPasswordInput(e.target.value)
    const isPasswordError = passwordInput === '' && dirty

    const toast = useToast()

    const submit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDirty(true)
        try {
            const user = { username: usernameInput, password: passwordInput } as ICredentials
            const token = await api.authentificateWithUser(user)
            await tokenRepository.set(token)
            api.setToken(token)
            app.setAuthentified(true)
        }
        catch (e: any) {
            const _e = e as AxiosError
            console.error(e)
            const status = _e.response?.status || _e.code
            toast.closeAll()
            return toast({
                title: t(`login.loginErrors.${status}.title`),
                description: t(`login.loginErrors.${status}.subtitle`),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [usernameInput, passwordInput, setDirty, toast, api, app, t, tokenRepository])

    useEffect(() => {
        if (app.authentified)
            navigate("/dashboard")
    }, [app, navigate])

    useInit(async () => {
        const progress = await onboardingRepository.get()
        if (!progress?.slider) {
            onOpen()
            onboardingRepository.set({ slider: true, dashboard: false, gonet: false })
        }
    })

    return (
        <Container>
            <OnboardingSlider isOpen={isOpen} onClose={onClose} />
            <Box p={10}>
                <Image m={"auto"} w={"3xs"} src={azilogo}></Image>
            </Box>
            <VStack spacing={6}>
                {
                    ready ? (
                        <>
                            <div className="login-language">
                                <LanguageSwitcher />
                            </div>
                            <form id="login-form" onSubmit={submit} style={{ width: "100%" }}>
                                <FormControl isInvalid={isUsernameError}>
                                    <FormLabel>{t("login.username")}</FormLabel>
                                    <Input id="username-input" data-testid="username-input" type='text' value={usernameInput} onChange={handleEmailInputChange} />
                                    {isUsernameError && <FormErrorMessage>{t("login.usernameError")}</FormErrorMessage>}
                                </FormControl>
                                <FormControl mt={4} isInvalid={isPasswordError}>
                                    <FormLabel>{t("login.password")}</FormLabel>
                                    <Input type='password' data-testid="password-input" value={passwordInput} onChange={handlePasswordInputChange} />
                                    {!isPasswordError && <FormErrorMessage>{t("login.passwordError")}</FormErrorMessage>}
                                </FormControl>
                                <HStack w={"full"} mt={6} justifyContent={"center"}>
                                    <Button id="login-button" type="submit" isDisabled={!usernameInput || !passwordInput} rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline'>
                                        {t("login.login")}
                                    </Button>
                                    {!app.installed && INSTALL && (
                                        <>
                                            <Spacer />
                                            <Button id="install-button-on-login-page" isDisabled={app.installed} onClick={app.install} rightIcon={<Icon as={MdInstallDesktop} />} colorScheme='teal'>
                                                {t("common.install")}
                                            </Button>
                                        </>
                                    )}


                                </HStack>
                            </form>
                            <Button colorScheme='blue' fontWeight={"medium"} className="whatis-button" variant={"link"} onClick={onOpen}>{t("onboarding.whatis")}</Button>
                        </>
                    ) :
                        (<Spinner size={"xl"} />)
                }
            </VStack >
        </Container >
    )
}