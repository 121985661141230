import { Virtuoso } from 'react-virtuoso'
import { useTranslation } from 'react-i18next';
import { Text, Box, Card, CardBody, CardHeader, Heading, Input, InputGroup, InputLeftElement, Skeleton, HStack, VStack, Button, InputRightElement, CloseButton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { ChangeEvent, useCallback, useContext, useMemo, useState, } from 'react';
import { AuthService, useService } from "portail-api-client";
import { GOnetMunicipalite } from "portail-api-models"
import { OpenWith } from './open-with.component';
import { Session } from '../../../types/session.type';
import GOnet from './session/gonet.component';
import { DashboardContext } from '../contexts/dashboard.context';
import { GeolocateMe } from './geolocateme.component';
const replaceSpecialCharacters = require('replace-special-characters');


interface IProps {
    onClick: (s: Session) => void,
    customScrollParent: HTMLElement
}
export default function Municipalities({ onClick, customScrollParent }: IProps) {

    const { t } = useTranslation()
    const [filter, setFilter] = useState("")
    const api = useService(AuthService)
    const { ready, municipalities } = useContext(DashboardContext)

    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value)
    }, [setFilter])

    const applySort = useCallback((a: GOnetMunicipalite, b: GOnetMunicipalite) => {
        if (a.nom < b.nom) {
            return -1;
        }
        if (a.nom > b.nom) {
            return 1;
        }
        return 0;
    }, [])

    const applyFilter = useCallback((m: GOnetMunicipalite) => {
        if (!filter) return true
        const codeMuniFilter = parseInt(filter)
        if (!isNaN(codeMuniFilter)) return m.code.includes(filter)
        const normalizedMuni = replaceSpecialCharacters(m.nom.toLowerCase())
        const normalizedMRC = replaceSpecialCharacters(m.mrc.nom.toLowerCase())
        const normalizedTarget = normalizedMuni + normalizedMRC
        const normalizedFilter = replaceSpecialCharacters(filter.toLowerCase())
        const filterWithAbbreviations = normalizedFilter.replace("st", "saint")
        const filters = [normalizedFilter, filterWithAbbreviations]
        return filters.some(f => normalizedTarget.includes(f))
    }, [filter])

    const emptyFilter = useCallback(() => {
        setFilter("")
    }, [setFilter])

    const filteredMunicipalities = useMemo(() => municipalities.filter(m => applyFilter(m)), [municipalities, applyFilter])
    const sortedMunicipalities = useMemo(() => filteredMunicipalities.sort(applySort), [filteredMunicipalities, applySort])

    return (
        <Box>
            <HStack id='municipalities-search'>
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <SearchIcon color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' value={filter} onChange={onInputChange} placeholder={`${t("dashboard.search")}`} />
                    <InputRightElement>
                        <CloseButton onClick={emptyFilter} />
                    </InputRightElement>
                </InputGroup>
                <GeolocateMe onGeolocated={onClick} />
            </HStack>
            {ready ? <Virtuoso
                style={{ height: 85.19 * sortedMunicipalities.length }}
                customScrollParent={customScrollParent}
                data={sortedMunicipalities}
                itemContent={(index, m) => (
                    <Card id='municipalities' mt={4} p={3} key={m.code + m.designation}>
                        <HStack>

                            <Button height={"auto"} p={1} flexGrow={1} justifyContent={"start"} variant={"ghost"} onClick={() => onClick({ component: GOnet, props: { municipality: m, gde: m.services.gonetprive ? api.getGDE() : undefined } })}>
                                <VStack alignItems={"start"} width={"full"}>
                                    <CardHeader p={0} width={"full"}>
                                        <Heading textAlign={"start"} size='md' className="ellipsis">{m.nom} ({m.code})</Heading>
                                    </CardHeader>
                                    <CardBody p={0} width={"full"}>
                                        <Text textAlign={"start"} className="ellipsis" fontWeight={"light"}>{t("designations." + m.designation.nom)} - MRC {m.mrc.nom}</Text>
                                    </CardBody>
                                </VStack>
                            </Button>

                            <OpenWith municipality={m} onOpenWith={onClick} />
                        </HStack>
                    </Card>
                )}
            /> : ([1, 2, 3, 4, 5, 6, 7, 8, 9]).map((i) => (
                <Card key={i} p={3}>
                    <CardHeader p={0} pb={1}>
                        <Skeleton><Heading size='md'>sad</Heading></Skeleton>
                    </CardHeader>
                    <CardBody p={0}>
                        <Skeleton><Text>salut</Text></Skeleton>
                    </CardBody>
                </Card>
            ))
            }
        </Box >
    );
}