import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router"

export function useRoutingDisclosure(path: string) {

    const navigate = useNavigate()
    const location = useLocation()


    const isOpen = useMemo(() => {
        return location.pathname.includes(path)
    }, [location, path])

    const onOpen = useCallback(() => {
        navigate(path, { state: { backgroundLocation: true } })
    }, [navigate, path])

    const onClose = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return { isOpen, onOpen, onClose }
}