import { Slide, Image, HStack, Button, Spacer, CloseButton } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from "swiper"
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./onboarding-slider.component.css"
import { SwiperItem } from './swiper-item.component';
import allmuni from "../../../../assets/onboarding/allmuni.png"
import recent from "../../../../assets/onboarding/recent.png"
import copypaste from "../../../../assets/onboarding/copypaste.png"
import openwith from "../../../../assets/onboarding/openwith.png"
import installable from "../../../../assets/onboarding/installable.png"
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const teal = "#319795"
const black = "black"
const white = "white"

interface IProps {
    isOpen: boolean
    onClose: () => void
}
export function OnboardingSlider({ isOpen, onClose }: IProps) {

    const [swiper, setSwiper] = useState<SwiperClass>()
    const [index, setIndex] = useState(0)
    const [isEnd, setIsEnd] = useState(false)
    const [isStart, setIsStart] = useState(true)
    const { t } = useTranslation()

    const resetAndClose = useCallback(() => {
        onClose()
        setTimeout(() => swiper?.slideTo(0), 100)
    }, [onClose, swiper])

    const bottomBarClassName = useMemo(() => {
        return "bottom-bar " + ((index % 2 === 0) ? "" : "bottom-bar-white")
    }, [index])

    const xClassName = useMemo(() => {
        return "close-button-login-onboarding " + ((index % 2 === 0) ? "" : "close-button-login-onboarding-white")
    }, [index])

    return (
        <Slide className='onboarding-slider' direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
            <Swiper
                className='onboarding-slider'
                pagination={{
                    dynamicBullets: true,
                    clickable: true
                }}
                onSlideChange={(_swiper) => { setIsStart(_swiper.isBeginning); setIsEnd(_swiper.isEnd); setIndex(_swiper.activeIndex) }}
                onSwiper={(_swiper) => setSwiper(_swiper)}
                modules={[Pagination]}
            >
                <SwiperSlide><SwiperItem body={<Image src={allmuni} />} header={t("onboarding.slide1.header")} subheader={t("onboarding.slide1.subheader")} backgroundColor={white} color={black} /></SwiperSlide>
                <SwiperSlide><SwiperItem body={<Image src={recent} />} header={t("onboarding.slide2.header")} subheader={t("onboarding.slide2.subheader")} backgroundColor={teal} color={white} /></SwiperSlide>
                <SwiperSlide><SwiperItem body={<Image src={copypaste} />} header={t("onboarding.slide3.header")} subheader={t("onboarding.slide3.subheader")} backgroundColor={white} color={black} /></SwiperSlide>
                <SwiperSlide><SwiperItem body={<Image src={installable} />} header={t("onboarding.slide5.header")} subheader={t("onboarding.slide5.subheader")} backgroundColor={teal} color={white} /></SwiperSlide>
                <SwiperSlide><SwiperItem body={<Image src={openwith} />} header={t("onboarding.slide4.header")} subheader={t("onboarding.slide4.subheader")} backgroundColor={white} color={black} /></SwiperSlide>
            </Swiper>
            <HStack className={bottomBarClassName}>
                <Button isDisabled={isStart} variant={"link"} onClick={() => swiper?.slidePrev()}>{t("common.back")}</Button>
                <Spacer />
                {!isEnd && <Button variant={"link"} onClick={() => swiper?.slideNext()}>{t("common.next")}</Button>}
                {isEnd && <Button variant={"link"} onClick={resetAndClose}>{t("common.done")}</Button>}
            </HStack>
            <CloseButton colorScheme='black' className={xClassName} onClick={resetAndClose} />
        </Slide>
    )
}