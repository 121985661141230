import { useService } from "portail-api-client"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useInit } from "../../../hooks/init.hook"
import { OnbaordingRepository } from "../../../repositories/onboarding.repository"
import { Step } from "react-joyride"
import Joyride from "react-joyride"
import { AppContext } from "../../../app.context"
import { INSTALL } from "../../../pwa-install";

export function DashboardJoyride() {

   const { t } = useTranslation()
   const { installed } = useContext(AppContext)
   const onboardingRepository = useService(OnbaordingRepository)
   const [showJoyride, setShowJoyride] = useState(false)

   const joyrideSteps: Array<Step> = useMemo(() => {

      const _steps: Array<Step> = [
         {
            disableBeacon: true,
            target: "#municipalities-search",
            floaterProps: { placement: "bottom" },
            content: t("onboarding.joyride.search")
         },
         {
            disableBeacon: true,
            target: "#municipalities",
            floaterProps: { placement: "top" },
            content: t("onboarding.slide1.subheader")
         },
         {
            disableBeacon: true,
            target: "#geolocation-button",
            floaterProps: { placement: "top" },
            content: t("onboarding.joyride.geolocate")
         }
      ]

      if (installed || !INSTALL) return _steps

      return [..._steps, {
         disableBeacon: true,
         target: "#dashboard-install-button",
         content: t("onboarding.joyride.install")
      }]

   }, [installed, t])

   useInit(async () => {
      const progress = await onboardingRepository.get()
      if (!progress?.dashboard) {
         setShowJoyride(true)
         onboardingRepository.set({ slider: !!progress?.slider, dashboard: true, gonet: !!progress?.gonet })
      }
   })

   return (
      <>
         {showJoyride && <Joyride
            continuous={true}
            locale={{ back: t("common.back"), close: t("common.close"), last: t("common.last"), next: t("common.next"), open: t("common.open"), skip: t("common.skip") }}
            steps={joyrideSteps}
            disableScrolling
         />
         }
      </>
   )
}